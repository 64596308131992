import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";

import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';

import VBSearchInput from '@virkesborsen/vb-js-commons/lib/components/new/vbSearchInput';

import { PropsData, VisaValda } from '../context';
import { getPropStatus, fetchPropertyData, searchProps, getProp, generateProp, unlockProperty, deleteProperty } from '../api';

export default function PropSelector() {
    const propsContext = React.useContext(PropsData);
    const visaValdaContext = React.useContext(VisaValda);

    const [loading, setLoading] = React.useState(false);

    const [collapsed, setCollapsed] = React.useState(true);
    const [view, setView] = React.useState('props');
    const [searchString, setSearchString] = React.useState('');
    const [searchResult, setSearchResult] = React.useState([]);

    const [errorModal, setErrorModal] = React.useState(false);
    const [unlockPropModal, setUnlockPropModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [infoModal, setInfoModal] = React.useState({show: false});

    const [focus, setFocus] = React.useState('');
    const [searchTimeout, setSearchTimeout] = React.useState(null);

    React.useEffect(() => {
        if(propsContext.props.length == 0) {
            setView('add')
        }

        let CookieProps = Cookies.get('activeProps')
        if(CookieProps && CookieProps != '') {
            CookieProps = CookieProps.split(',')
        } else {
            CookieProps = null
        }

        if(!CookieProps) {
            setCollapsed(false)
        }

        propsContext.props.map(async (p) => {
            if(p.status == 'data' && !p.data) {
                let preSelected = CookieProps && CookieProps.includes(p.ref.toString()) ? true : false
                p.checked = preSelected
                p.active = preSelected
                fetchPropData(p)
            }

            if(p.status == 'running') {
                checkPropStatus(p)
            }
        })
    }, []);

    const PropItem = styled.div`
        .lock-icon {
            background-image: url("${Window.static_path}img/icons/lock.svg");
        }

        &:hover {
            .lock-icon {
                background-image: url("${Window.static_path}img/icons/unlocked.svg");
            }
        }
    `

    const LockedUnlockedIcon = styled.div`
        width: 20px;
        height: 20px;
        background-size: contain;
        display: inline-block;
    `

    const CheckIcon = styled.div`
        width: 25px;
        height: 25px;
        &.checked {
            background-size: contain;
            background-image: url("${Window.static_path}img/icons/checkmark.svg");
        }
    `

    const WarningIcon = styled.div`
        width: 20px;
        height: 20px;
        background-size: contain;
        background-image: url("${Window.static_path}img/icons/risks.svg");
    `

    const TrashIcon = styled.div`
        width: 25px;
        height: 25px;
        background-size: contain;
        background-image: url("${Window.static_path}img/icons/trash.svg");
    `

    const ForestandFileIcon = styled.div`
        width: 18px;
        height: 25px;
        background-size: cover;
        background-image: url("${Window.static_path}img/icons/forestand-colored.svg");
    `

    const setActiveCookie = () => {
        const activeProps = propsContext.props.filter(p => p.active)
        Cookies.set('activeProps', activeProps.map(p => p.ref).join(','))
    }

    const fetchPropData = async (p) => {
        const data = await fetchPropertyData(p.ref, p.type)
        data && typeof data == 'object' ? p.data = data : p.status = 'warning'

        if(data) {
            if(p.type == 'PROP') {
                const propData = await getProp(p.ref)

                if(propData) {
                    p.border = propData.geojson.geometry.coordinates
                    p.area = propData.area
                }
            }
            
            if('data' in p) {
                let volume = 0
                data.sections.map(s => volume += s.volume)
                p.volume = volume
            }
        }
        
        propsContext.setOneProp(p)
    }

    const checkPropStatus = async (p) => {
        let status = await getPropStatus(p.ref)
        
        if(status == 'broken' || status == 'data') {
            p.status = status
            propsContext.setOneProp(p)
            if(status == 'data') {
                fetchPropData(p)
            }
        } else {
            setTimeout(() => {
                checkPropStatus(p); 
            }, 2000);
        }
    }

    const addProp = (p) => {
        setView('props')
        setLoading({prop: p.beteckning})
        getProp(p.registerenhet).then(geo => {
            generateProp({geometry: geo.geojson.geometry, prop: p}).then(resp => {
                if(resp.status == 'to_small') {
                    setInfoModal({message: `Fastighet ${p.beteckning} är för liten`, show: true})
                }

                if(resp.status == 'set_visible' || resp.status == 'running') {
                    propsContext.setOneProp(resp.data)
                    checkPropStatus(resp.data)
                }

                setLoading(false)
            })
        })
    }

    const handlePropClick = (p) => {
        if(p.locked && (p.status == 'data' || p.status == 'running')) {
            setUnlockPropModal(p)
        }
        
        if(!p.locked && ('data' in p)) {
            p.checked = !p.checked
            propsContext.setOneProp(p)
        }

        if(p.status == 'warning' || p.status == 'broken') {
            setInfoModal({title: 'Tyvärr kunde vi inte generera din skogsbruksplan', message: <>Det verkar ha uppstått ett problem med att skapa skogsbruksplanen för {p.designation}. Kontakta oss på <a className='fw-bold' href='mailto:info@treebula.se'>info@treebula.se</a> eller <a className='fw-bold' href='tel:00468339944'>08-33 99 44</a> så hjälper våra rådgivare dig att lösa detta så snabbt som möjligt.</>, show: true})
        }
    }

    const propStart = (p) => {
        if(p.status == 'data' || p.status == 'running') {
            if(p.loading) {
                return <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
            }
            if('data' in p && p.locked) {
                return <LockedUnlockedIcon className='lock-icon'/>
            }
            if((p.status == 'data' && !('data' in p)) || p.status == 'running') {
                return <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
            }
            if('data' in p) {
                return <CheckIcon className={`d-flex border border-2 ${p.checked ? 'checked' : ''}`} />
            }
        } else {
            return <WarningIcon className={`${p.status == 'broken' ? 'danger-filter' : p.status == 'warning' ? 'warning-filter' : ''}`} />
        }
    }

    const propsInfoText = () => {
        let desc = <>Som gratis medlem får du låsa upp max 5 gratis begränsad skogsbruksplaner. Välj vilken fastigheter du vill generera skogsbruksplan på nedan</>;
        if (Window.react_data.used_props >= Window.react_data.allowedProps) {
            desc = <>Du är inte Pluskund och använder just nu {Window.react_data.used_props}/{Window.react_data.allowedProps} gratis begränsade skogsbruksplaner. Vill du se all information? <a href="/primakund/">Bli pluskund här</a>. Eller vill du låsa upp fler fastigheter får du kontakta oss via <a href="mailto:info@virkesborsen.se">mejl</a> eller ringa oss på <a href="tel:00468339944">08-33 99 44</a>.</>;
        }

        if (Window.user_data.isPremium) {
            if (Window.react_data.used_props >= Window.react_data.allowedProps) {
                desc = <>Du är pluskund och använder just nu {Window.react_data.used_props}/{Window.react_data.allowedProps} fastigheter, vill du låsa upp och aktivera Pluskund på fler fastigheter får du kontakta oss via mejl på <a href="mailto:info@virkesborsen.se">info@virkesborsen.se</a> eller ringa oss på <a href="tel:00468339944">08-33 99 44</a>.</>;
            } else {
                desc = <>Du är Pluskund och använder just nu {Window.react_data.used_props}/{Window.react_data.allowedProps} skogsbruksplaner välj vilken fastighet du vill låsa upp och aktivera Pluskund på.</>;
            }
        }

        return desc
    }

    const visaValdaClicked = () => {
        let props = propsContext.props
        props.map(p => {
            if(p.checked) {
                p.active = true
            } else {
                p.active = false
            }
        })
        propsContext.setProps(props)
        setActiveCookie();
        visaValdaContext.setVisaValda(true)
        setCollapsed(true);
    }

    const setSelectorTitle = () => {
        let activeProps = propsContext.props.filter(p => p?.active)
        if(activeProps.length == 0) {
            return 'Välj fastighet'
        } else if(activeProps.length == 1) {
            return <span className='text-nowrap overflow-hidden'>{activeProps[0].designation.length < 21 ? activeProps[0].designation : activeProps[0].designation.substring(0, 20)+'...'}</span>
        } else {
            return `${activeProps.length} fastigheter valda`
        }
    }

    const generateForestandSBP = (e) => {
        var input = e.target;

        var data = new FormData()
        data.append('file', input.files[0])

        setLoading({sbo: input.files[0].name})

        fetch('/map-api/import-forestand-to-sbp/', {
            method: 'POST',
            body: data,
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken')
            }
        }).then(resp => {
            if(resp.ok) {
                resp.json().then(data => {
                    propsContext.setOneProp(data)
                    setLoading(false)
                })
            } else {
                setLoading(false)
                setInfoModal({title: 'Tyvärr kunde vi inte generera din skogsbruksplan', message: <>Det verkar ha uppstått ett problem med att skapa skogsbruksplanen för {input.files[0].name}. Kontakta oss på <a className='fw-bold' href='mailto:info@treebula.se'>info@treebula.se</a> eller <a className='fw-bold' href='tel:00468339944'>08-33 99 44</a> så hjälper våra rådgivare dig att lösa detta så snabbt som möjligt.</>, show: true})
            }
        })
    }

    const sortProps = (list) => {
        return list.sort((a, b) => {
            // Prioritize active items
            if (a.active !== b.active) {
                return b.active - a.active;
            }
        
            // Prioritize status
            const statusOrder = ['data', 'running', 'warning', 'broken'];
            const statusA = statusOrder.indexOf(a.status);
            const statusB = statusOrder.indexOf(b.status);
            if (statusA !== statusB) {
                return statusA - statusB; 
            }
        
            // Within the same status, prioritize unlocked items
            if (a.locked !== b.locked) {
                return a.locked - b.locked;
            }
        
            // If all else fails, default to alphabetical order by name
            return a.designation.localeCompare(b.designation);
        })
    }
    
    return <div className='d-flex flex-column bg-neutral-cloud shadow rounded user-select-none p-2'>
        <div className='bg-white p-2'>
            <div role='button' className='d-flex justify-content-between neutral-pebble' onClick={() => setCollapsed(!collapsed)}>{setSelectorTitle()}<div className={`${collapsed ? 'rotate-50' : ''}`} style={{transition: 'transform .2s ease-in-out'}}><img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} height='20px' width='20px' /></div></div>


            <Collapse in={!collapsed}>
                <div>
                    {view == 'props' && <>
                        <p className='n-fs-6 mb-0 mt-2'>{propsInfoText()}</p>
                        <button className='n-btn btn-forest-green btn-sm rounded w-100 my-1' onClick={() => setView('add')}>Lägg till fastighet +</button>
                        <div className='overflow-auto' style={{maxHeight: 'calc(-470px + 100vh)'}}>
                            {propsContext.props && propsContext.props.length > 0 && sortProps(propsContext.props).map(p => (
                                <div key={p?.label} className={`d-flex ${p?.active ? 'bg-primary-core hover-bg-primary-core-200' : p.status == 'data' ? 'hover-bg-primary-core-200' : 'grey bg-neutral-cloud hover-bg-neutral-cloud-300'}`}>
                                    <PropItem role='button' onClick={() => handlePropClick(p)} className='d-flex justify-content-start align-items-center n-fs-6 w-100 p-2'>
                                        <div className='d-flex justify-content-center' style={{width: '25px'}}>{propStart(p)}</div>{p.type == 'SBO' && <ForestandFileIcon className='ms-1' />}<span className='ms-1'>{p.designation}</span>
                                    </PropItem>
                                    {!p.loading && <div className='d-flex align-items-center p-2'>
                                        <TrashIcon className='hover-supp-redwood-filter' role="button" onClick={() => setDeleteModal(p)} />
                                    </div>}
                                </div>
                            ))}
                            {loading && 'sbo' in loading && <div className='d-flex grey bg-neutral-cloud hover-bg-neutral-cloud-300'>
                                <PropItem className='d-flex justify-content-start align-items-center n-fs-6 w-100 p-2'>
                                    <div className='d-flex justify-content-center' style={{width: '25px'}}>
                                        <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
                                    </div>
                                    <span className='ms-1'>{loading.sbo}</span>
                                </PropItem>
                            </div>}
                            {loading && 'prop' in loading && <div className='d-flex grey bg-neutral-cloud hover-bg-neutral-cloud-300'>
                                <PropItem className='d-flex justify-content-start align-items-center n-fs-6 w-100 p-2'>
                                    <div className='d-flex justify-content-center' style={{width: '25px'}}>
                                        <div className="spinner-border" style={{width: "20px", height: "20px"}} role="status"></div>
                                    </div>
                                    <span className='ms-1'>{loading.prop}</span>
                                </PropItem>
                            </div>}
                        </div>
                        <button onClick={() => visaValdaClicked()} className='n-btn btn-core btn-sm rounded w-100 mt-2'>Visa valda</button>
                        <div className='bg-supp-spruce w-100 my-2' style={{height: '2px'}}></div>
                        {loading ? <p className='n-fs-7 mb-0'>Vi bearbetar din skogsbruksplan fil.</p> : <p role='button' className='n-fs-7 mb-0' onClick={() => document.querySelector("#hidden_file_upload_forestand").click()}>Du kan genom att klicka <b>här</b> importera en skogsbruksplan i formatet forestand (.xml)</p>}

                        <input onChange={(e) => generateForestandSBP(e)} id="hidden_file_upload_forestand" className='position-absolute' type="file" style={{ opacity: 0, width: "1px", height: "1px", zIndex: -1000 }} />
                    </>}
                    
                    {view == 'add' && <div>
                        <p className='fw-bold mt-2'>Lägg till fastighet <button className='n-btn btn-forest-green btn-sm rounded float-end py-1 px-2' onClick={() => setView('props')}>Avbryt</button></p>
                        <p className='n-fs-7 mb-2'>Skriv in din fastighetsbeteckning nedan (Exempel Eskilstuna Kullen 1:2)</p>

                        <VBSearchInput
                            id='propSearchInput'
                            placeholder='Sök eller eg: Sevedstorp 1:8'
                            initial={searchString}
                            hasFocus={focus}
                            onBlur={() => setFocus('')}
                            onChange={async (val) => {
                                setFocus('propSearchInput')
                                setSearchString(val)

                                if(searchTimeout) {
                                    clearTimeout(searchTimeout)
                                }
                                const timeout = setTimeout(() => {
                                    searchProps(val).then(res => {setSearchResult(res)})
                                }, 400)

                                setSearchTimeout(timeout)
                            }}
                            searchResult={
                                searchString !== '' && 
                                    searchResult && searchResult.length > 0 ? 
                                    searchResult.map(prop => <p className="option w-100 ps-2" onClick={() => addProp(prop) }>{prop.beteckning}</p>) : 
                                    <p>Inga träffar på den sökningen.</p>
                            }
                        />
                    </div>}
                </div>
            </Collapse>
        </div>
        

        <Modal show={errorModal} onHide={() => setErrorModal(false)} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                {errorModal.title ? errorModal.title : <h4 className="mb-0">Oops! Något gick fel.</h4>}
            </Modal.Header>
            <Modal.Body>
                {errorModal.message ? errorModal.message : <p className='n-fs-5 neutral-charcoal mb-0'>Ring <a href="tel:08339944" className="fw-bold neutral-charcoal">08-33 99 44</a> så hjälper vi dig vidare.</p>}
                <h2 className="n-fs-4 neutral-charcoal">Oops! Något gick fel.</h2>
            </Modal.Body>
            <Modal.Footer className="justify-content-end border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setErrorModal(false)}>Fortsätt</button>
            </Modal.Footer>
        </Modal>

        <Modal show={unlockPropModal} onHide={() => setUnlockPropModal(false)} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h4 className="mb-0">Lås upp en ny fastighet</h4>
            </Modal.Header>
            <Modal.Body>
                <p className='n-fs-5 neutral-charcoal mb-0'>Är du säker på att du vill låsa upp {unlockPropModal.designation}?</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-between border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setUnlockPropModal(false)}>Lås inte upp</button>
                <button type="button" className="n-btn btn-core" onClick={() => {
                    setUnlockPropModal(false)
                    let p = unlockPropModal
                    p.loading = true
                    propsContext.setOneProp(p)
                    unlockProperty(unlockPropModal.ref).then(resp => {
                        let p = unlockPropModal
                        p.loading = false
                        if(resp.ok) {
                            p.locked = false
                            propsContext.setOneProp(p)
                        } else {
                            propsContext.setOneProp(p)
                            resp.json().then(json => {
                                if('error' in json && json.error == "exceeding_delete_limit" ) {
                                    setErrorModal({message: <>Vårt system ser att du är intresserad av flera fastigheter. Hör av dig till oss på <a href="mailto:info@virkesborsen.se:">info@virkesborsen.se</a> så hjälper vi dig.</>})
                                } else {
                                    setErrorModal(true)
                                }
                            })
                        }
                    })
                }}>Lås upp</button>
            </Modal.Footer>
        </Modal>

        <Modal show={deleteModal} onHide={() => setDeleteModal(false)} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h4 className="mb-0">Radera fastighet</h4>
            </Modal.Header>
            <Modal.Body>
                <p className='n-fs-5 neutral-charcoal mb-0'>Är du säker på att du vill radera {deleteModal.designation}?</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-between border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setDeleteModal(false)}>Avbryt</button>
                <button type="button" className="n-btn btn-core" onClick={() => {
                    let prop = deleteModal
                    prop.loading = true
                    propsContext.setOneProp(prop)
                    setDeleteModal(false)
                    deleteProperty(prop.ref, prop.type).then(() => {
                        let props = propsContext.props.filter(p => p.ref != prop.ref)
                        propsContext.setProps(props)

                        if(prop.active) {
                            visaValdaContext.setVisaValda(true)
                        }
                    })}}>Radera</button>
            </Modal.Footer>
        </Modal>

        <Modal show={infoModal.show} onHide={() => setInfoModal({...infoModal, show: false})} contentClassName="bg-neutral-cloud-200 p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h4 className="mb-0">{infoModal.title ? infoModal.title : 'Något gick fel'}</h4>
            </Modal.Header>
            <Modal.Body>
                <p className='n-fs-5 neutral-charcoal mb-0'>{infoModal.message ? infoModal.message : 'Kontakta oss och får hjälp direkt från våra rådgivare.'}</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setInfoModal({...infoModal, show: false})}>Okej</button>
            </Modal.Footer>
        </Modal>
    </div>
}