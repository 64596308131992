import React from 'react';
import * as Opt from '../editor/Helpers';
import TextEditor from '../editor/fields/TextEditor';
import ColorSelector from '../editor/fields/ColorSelector';
import MediaSelector from '../editor/fields/MediaSelector';


class ContentSectionLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
            <div className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50 d-flex' : this.props.visibility == "dt" ? 'd-none d-lg-flex' : this.props.visibility == "mob" ? 'd-lg-none' : 'd-flex'}`}> 
            <div className='container'>
            
            <div className={`rounded-5 p-lg-5 p-4 col-12 d-flex flex-column flex-lg-row bg-${this.props.backgroundColor}`}>

              {/* Main Header here */}
              <div className='col-lg-6 col-12 me-lg-3'>
                <p className={`n-fs-6 ${this.props.taglineColor}`}>{this.props.tagline}</p>
                <h4 className={`n-fs-2 fw-medium ${this.props.titleColor}`}>{this.props.title}</h4>
                <div className={`d-block quill-text my-4 ${Opt.CalculateFontSize(this.props.textBodyFontSizes)}`} dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
              </div>

              {/* Section here */}
              <div className='col-lg-6 col-12'>

                {this.props.section.map((section, index) => (
                  <div key={index} className={`col-12 d-flex flex-row ${index > 0 ? 'mt-4' : ''}`}>
                    <div className='col-2 col-md-1 vertical-align-middle pe-4 overflow-hidden'>
                      <p className={`n-fs-5 align-text-center ${section.numberColors} bg-${section.numberBackgroundColor} d-flex justify-content-center rounded-3 px-3 py-1 mb-2 text-align-center`}>{`${section.src}`.includes('svg') ? <img className={`${section.numberColors}-filter`} style={{ width: "20px", height: "20px" }} src={section.src} /> : section.number }</p>
                      {index < this.props.section.length - 1 && (
                        <div className="d-flex ms-3" style={{ height: '80%', width: '6px' }}>
                          <div className={`vr ${section.lineColor} ${this.props.lineVisible == "yes" ? 'opacity-100' : 'opacity-0'}`}></div>
                        </div>
                      )}
                    </div>
                    <div className='col-10 ms-md-2 ms-1'>
                      <h4 className={`n-fs-5 ${section.titleColor} text-break`}>{section.title}</h4>
                      <div className={`d-block quill-text my-2 ${Opt.CalculateFontSize(section.textBodyFontSizes)}`} dangerouslySetInnerHTML={{ __html: section.text }}></div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
            </div>
            </div>
    )
  }
}

ContentSectionLayout.defaultProps = {
  lineVisible: "yes",
  visibility: "",
  backgroundColor: 'neutral-cloud-500',
  tagline: 'Tagline',
  taglineColor: 'neutral-charcoal',
  title: 'Medium length section heading goes here',
  titlColor: 'neutral-charcoal',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  textBodyFontSizes: '',
  section: [
    {
      number: '1',
      src: 'number',
      numberColors: 'neutral-charcoal',
      numberBackgroundColor: 'primary-core',
      lineColor: 'neutral-charcoal',
      title: 'Få bättre betalt',
      titleColor: 'neutral-charcoal',
      textBodyFontSizes: '',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
    },
    {
      number: '2',
      src: 'number',
      numberColors: 'neutral-charcoal',
      numberBackgroundColor: 'primary-core',
      lineColor: 'neutral-charcoal',
      title: 'Få bättre betalt',
      titleColor: 'neutral-charcoal',
      textBodyFontSizes: '',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
    },
    {
      number: '3',
      src: 'number',
      numberColors: 'neutral-charcoal',
      numberBackgroundColor: 'primary-core',
      lineColor: 'neutral-charcoal',
      title: 'Få bättre betalt',
      titleColor: 'neutral-charcoal',
      textBodyFontSizes: '',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
    },
    {
      number: '4',
      src: 'number',
      numberColors: 'neutral-charcoal',
      numberBackgroundColor: 'primary-core',
      lineColor: 'neutral-charcoal',
      title: 'Få bättre betalt',
      titleColor: 'neutral-charcoal',
      textBodyFontSizes: '',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
    }],
}

ContentSectionLayout.fields = {
  backgroundColor: {
    type: "custom",
    render: (props) => <ColorSelector {...props} />
  },
  visibility: {
    type: "select",
    options: Opt.visibilityOptions
  },
  lineVisible: {
    type: "select",
    options: Opt.yesNo
  },
  tagline: { type: "text" },
  taglineColor: {
    type: "select",
    options: Opt.colorVariables
  },
  title: { type: "text" },
  titleColor: {
    type: "select",
    options: Opt.colorVariables
  },
  textBodyFontSizes: Opt.fontSizeOptions,
  text: { type: "custom",
    render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} />
  },
  section: {
    type: "array",
    arrayFields: {
      number: { type: "number" },
      numberColors: {
        type: "select",
        options: Opt.colorVariables
      },
      numberBackgroundColor: {
        type: "select",
        options: Opt.colorVariables
      },
      lineColor: { 
        type: "select",
        options: Opt.colorVariables
      },
      title: { type: "text" },
      titleColor: {
        type: "select",
        options: Opt.colorVariables
      },
      src: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={'icon'} type={'icon'} onChange={onChange} value={value} />,
      },
      textBodyFontSizes: Opt.fontSizeOptions,
      text: { type: "custom",
      render: ({ name, onChange, value }) => <TextEditor name={name} value={value} onChange={onChange} />
      },
    },
    defaultItemProps: ContentSectionLayout.defaultProps.section[0],
  },
}

export default ContentSectionLayout;