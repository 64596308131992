import React from 'react';
import { DropZone } from "@measured/puck";
import * as Comp from '../editor/PuckComps';
import * as Opt from '../editor/Helpers';
import RadiusEditor from '../editor/fields/RadiusEditor';
import ColorSelector from '../editor/fields/ColorSelector';
import MediaSelector from '../editor/fields/MediaSelector';

class CardComponent extends React.Component {
    constructor() {
        super();
    }

    selectPaddingType(spacing) {
        if (this.props.morePadding != '0') {
            return spacing.filter((item) => item.type != 'p')
        } else {
            return spacing
        }
    }

    render() {
        let cardStyle = {}
        if (this.props.backgroundImage) {
            cardStyle.background = `url(${this.props.backgroundImage}) no-repeat`
            cardStyle.backgroundSize = 'cover'
            cardStyle.zIndex=1;
            if(this.props.backgroundImagePosition){
                cardStyle.backgroundPosition=this.props.backgroundImagePosition;
            }
        }
        let link = {}
        if(this.props.link){
            link = {href:this.props.link}
        }
        return <a {...link}  className={`${window.location.pathname.includes('editor') && (["dt", "mob"].filter((i) => i === this.props.visibility).length) ? 'opacity-50' : this.props.visibility == "dt" ? 'd-none d-lg-block' : this.props.visibility == "mob" ? 'd-lg-none' : ''} overflow-hidden w-100 h-100 ${(Array.isArray(this.props.borderRadius)?this.props.borderRadius:[]).reduce((acum, itm) => acum+itm.radius+" ", "")} bg-${!this.props.backgroundImage ? this.props.backgroundColor : ''} ${this.props.boxShadow} ${Opt.CalculateSpacing(this.selectPaddingType(this.props.spacing))}`} style={ this.props.backgroundImage ? {...cardStyle, padding: `${this.props.morePadding}`} : { padding: `${this.props.morePadding}`, cursor: (this.props.link ? "pointer" : "default")} }>
            <div className={`w-100 d-flex flex-wrap ${this.props.horizontalAlign} ${this.props.verticalAlign} ${this.props.contentDirection}`}>
                <DropZone zone='insertindexhere' disallow={["Columns"]} />

                {this.props.content.map((comp) => {
                    // Loop over all the components for that column and render them within
                    let Component = Comp[comp.type]
                    return <Component {...comp.props} />
                })}
            </div>
        </a>
    }
}

CardComponent.defaultProps = {
    visibility: "",
    morePadding: "0",
    boxShadow: "shadow-none",
    horizontalAlign: "justify-content-start",
    verticalAlign: "align-items-stretch",
    contentDirection: "column",
    content: [],
    borderRadius: [],
    spacing:[],
    backgroundColor: 'neutral-stone-500', 
    cardHeight:"250px",
    link: ""
}

CardComponent.fields = {
    id: {
        type: "custom",
        render: ({ name, onChange, value }) => (
            <p style={{fontSize:"12px"}}>{value}</p>
        )
    },
    backgroundImagePosition: {
        type: "select",
        options: ["top", "bottom", "center"].reduce((acum, itm) => {
            ["left", "right", "center"].forEach((itm2) => {
            acum.push({label:itm+" "+itm2, value:itm+" "+itm2})
            })
                return acum;
        }, [{label:"top", value:"top"}, {label:"bottom", value:"bottom"}, {label:"center", value:"center"}, {label:"left", value:"left"}, {label:"right", value:"right"}])
    }, 
    visibility: {
        type: "select",
        options: Opt.visibilityOptions
    },
    morePadding: {
        type: "text",
    },
    boxShadow: {
        type: "select",
        options: Opt.boxShadow
    },
    borderRadius:{
        type: "array",
        arrayFields: {
            radius:{
                type: "custom",
                render: ({name, onChange, value}) => <RadiusEditor name={name} onChange={onChange} value={value} />
            }
        }
    },
    spacing:Opt.spacingOptions,
    horizontalAlign: {
        type: "select",
        options: Opt.contentJustify,

    },
    verticalAlign: {
        type: "select",
        options: Opt.contentAlignItems,

    },
    contentDirection: {
        type: "select",
        options: [{ label: "Row", value: "row" }, { label: "Column", value: "column" }, { label: "Row Reverse", value: "row-reverse" }, { label: "Column Reverse", value: "column-reverse" }]
    },
    backgroundColor: {
        type: "custom",
        render: (props) => <ColorSelector {...props} />
    },
    backgroundImage: {
        type: "custom",
        render: ({ name, onChange, value }) => <MediaSelector name={name} type={'img'} onChange={onChange} value={value} />,
    },
    cardHeight: {
        type: "text",
    },
    link:{
        type: "text",
    }
}

export default CardComponent;