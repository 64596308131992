import React from 'react';
import styled from "styled-components";

import PropSelector from './comps/PropSelector';
import LineChart from './charts/LineChart';
import PieChart from './charts/PieChart';
import BarChart from './charts/BarChart';

import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBRadioGroup from '@virkesborsen/vb-js-commons/lib/components/new/vbRadioGroup';
import VBLoadingScreen from '@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import { PropsData, VisaValda } from './context';
import { updateSectionsProcentage, getSBPPdf } from './api';

import { ErrorModal } from '../../modals';

const PropselectorContainer = styled.div`
    width: 90vw;
    max-width: 500px;
    top: 10px;
`

const Span = styled.span`
    border: 1px solid #ccc;
    float: left;
    width: 12px;
    height: 12px;
    margin: 2px;
    background-color: ${props => props.bgColor}; 
`

export default function Overblick() {
    const propsContext = React.useContext(PropsData);
    const visaValdaContext = React.useContext(VisaValda)

    const [loading, setLoading] = React.useState(true);

    const [edit, setEdit] = React.useState({});

    const [totalArea, setTotalArea] = React.useState(false);
    const [totalVolume, setTotalVolume] = React.useState(false);
    const [avgGrowth, setAvgGrowth] = React.useState(false);
    const [TGLdata, setTGLdata] = React.useState(false);

    const [valuationChartData, setValuationChartData] = React.useState(false);
    const [fellingClassData, setFellingClassData] = React.useState(false);
    const [volumeAge, setVolumeAge] = React.useState(false);
    const [areaAge, setAreaAge] = React.useState(false);

    const [totalSolarHa, setTotalSolarHa] = React.useState(false);
    const [totalSolarEnergy, setTotalSolarEnergy] = React.useState(false);

    //const [totalCO2, setTotalCO2] = React.useState(false);
    const [totalEco, setTotalEco] = React.useState(false);

    const [ageGraphView, setAgeGraphView] = React.useState('area')

    // Modals
    const [errorModal, setErrorModal] = React.useState(false);

    const waitForData = async () => {
        const activeProps = propsContext.props.filter(p => p.active)
        if(activeProps.filter(p => p.data).length != activeProps.length || activeProps.filter(p => p.border).length != activeProps.filter(p => p.type == 'PROP').length) {
            setTimeout(() => waitForData(), 100)
        } else {
            setPriceLineData()
            setTotalValues()
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if(visaValdaContext.visaValda) {
            setPriceLineData()
            setTotalValues()
            visaValdaContext.setVisaValda(false)
        }
    }, [visaValdaContext.visaValda]);

    React.useEffect(() => {
        waitForData()
    }, []);

    const changeVolume = () => {
        let prop = propsContext.props.find(p => p.active == true)

        if (edit.volume == totalVolume) {
            setEdit({...edit, volume: false})
        } else if (edit.volume == 0 || totalVolume == 0) {
            setEdit({...edit, volume: false})
        } else {
            let prevValue = totalVolume
            let editValue = edit.volume
            setTotalVolume(false)
            setEdit({...edit, volume: false})
            updateSectionsProcentage(prop.ref, 'volume', editValue / prevValue, editValue).then(res => {
                if(res.ok) {
                    res.json().then(new_data => {
                        setTotalVolume(editValue)
                        prop.data = new_data
                        propsContext.setOneProp(prop)
                    })
                } else {
                    setTotalVolume(prevValue)
                    setErrorModal(true)
                }
            })
        }
    }

    const changeGrowth = () => {
        let prop = propsContext.props.find(p => p.active == true)

        if (edit.growth == avgGrowth) {
            setEdit({...edit, growth: false})
        } else {
            let prevValue = avgGrowth
            let editValue = edit.growth
            setAvgGrowth(false)
            setEdit({...edit, growth: false})
            updateSectionsProcentage(prop.ref, 'growth_rate', editValue / prevValue, editValue).then(res => {
                if(res.ok) {
                    res.json().then(new_data => {
                        setAvgGrowth(editValue)
                        prop.data = new_data
                        propsContext.setOneProp(prop)
                    })
                } else {
                    setAvgGrowth(prevValue)
                    setErrorModal(true)
                }
            })
        }
    }

    const setPriceLineData = () => {
        const activeProps = propsContext.props.filter(p => p.active)

        let curr_year = new Date().getFullYear();
        let data = {}
        let color = ''
        let bar = []
        
        activeProps.map(prop => {
            let growth_per_year = (prop.data.sections.reduce((a, b) => a + parseFloat(b.growth_rate), 0))
            let total_vol = (prop.data.sections.reduce((a, b) => a + parseFloat(b.volume), 0))

            color = prop.data.valuation_chart_data.line[0].color
            bar = prop.data.valuation_chart_data.bar

            for (let i=0; i < prop.data.valuation_chart_data.years.length; i++) {
                let year = prop.data.valuation_chart_data.years[i];
    
                let year_diff = curr_year - year;
                let growth_during_period = year_diff * growth_per_year
                let vol_historic = (total_vol - growth_during_period)
    
                prop.data.valuation_chart_data.line.forEach((itm) => {
                    if (itm.data.length > 0) {
                        if (data[year]) {
                            data[year] += Math.round((itm.data[i] * vol_historic) / 100000) * 100000
                        } else {
                            data[year] = Math.round((itm.data[i] * vol_historic) / 100000) * 100000
                        }
                    }
                })
            }
        })

        let data_list = []
        let year_list = []
        for (let year in data) {
            data_list.push(data[year])
            year_list.push(year)
        }
        
        setValuationChartData({bar: bar, line: [{ color: color, data: data_list }], years: year_list})
    }

    const setTotalValues = () => {
        const activeProps = propsContext.props.filter(p => p.active)

        let fellingClasses = []
        let allGrowthRate = []
        let TGL = {tall: 0, gran: 0, lov: 0}

        let volumeAge = new Array(11).fill(0);
        let areaAge = new Array(11).fill(0);
        
        activeProps.map(prop => {
            prop.data.sections.map(sect => {
                // TGL
                TGL.tall += Math.round(sect.tall * sect.volume / 100)
                TGL.gran += Math.round(sect.gran * sect.volume / 100)
                TGL.lov += Math.round(sect.lov * sect.volume / 100)

                if(fellingClasses.find(c => c.class == sect.felling_class)) {
                    fellingClasses.find(c => c.class == sect.felling_class).area += parseInt(sect.area)
                    fellingClasses.find(c => c.class == sect.felling_class).volume += sect.volume
                } else {
                    fellingClasses.push({class: sect.felling_class, area: parseInt(sect.area), volume: sect.volume})
                }

                if(parseFloat(sect.growth_rate) > 0) {
                    allGrowthRate.push(parseFloat(sect.growth_rate));
                }
                
                if(sect.age > 100) {
                    volumeAge[10] += sect.volume
                    areaAge[10] += parseInt(sect.area)
                } else {
                    volumeAge[Math.floor(sect.age / 10)] += sect.volume
                    areaAge[Math.floor(sect.age / 10)] += parseInt(sect.area)
                }
            })
        })

        TGL.total = TGL.tall + TGL.gran + TGL.lov
        TGL.tall = (TGL.tall / TGL.total) * 100
        TGL.gran = (TGL.gran / TGL.total) * 100
        TGL.lov = (TGL.lov / TGL.total) * 100

        setFellingClassData(fellingClasses)
        setAvgGrowth((allGrowthRate.reduce((total, rate) => total + rate, 0) / allGrowthRate.length).toFixed(2))
        setTGLdata(TGL)
        setVolumeAge(volumeAge)
        setAreaAge(areaAge)

        let total = activeProps.reduce((result, prop) => {
            // General
            result.volume += prop.volume
            result.area += prop.data.area

            // Solar
            result.solar_ha += parseFloat(prop.data.solar_ha)
            result.solar_energy.approx_ton_paper += prop.data.solar_energy.approx_ton_paper
            result.solar_energy.approx_villas += prop.data.solar_energy.approx_villas
            result.solar_energy.arrende_per_ha += prop.data.solar_energy.arrende_per_ha
            result.solar_energy.mil_with_truck += prop.data.solar_energy.mil_with_truck
            result.solar_energy.solar_energy += prop.data.solar_energy.solar_energy

            //result.co2.annual_co2_growth += prop.data.co2_data.annual_co2_growth
            //result.co2.carbon_permits += prop.data.co2_data.carbon_permits
            //result.co2.climate_neutral_felling += prop.data.co2_data.climate_neutral_felling
            //result.co2.co2 += prop.data.co2_data.co2

            result.eco.ha += prop.data.eco_credits.ha
            result.eco.credits += prop.data.eco_credits.credits
            result.eco.sek += prop.data.eco_credits.sek_per_credit

            return result
        }, {volume: 0, area: 0, solar_ha: 0, TGL: {tall: 0, gran: 0, lov: 0}, solar_energy: {approx_ton_paper: 0, approx_villas: 0, arrende_per_ha: 0, mil_with_truck: 0, solar_energy: 0}, eco: {ha: 0, credits: 0, sek: 0}})
        // , co2: {annual_co2_growth: 0, carbon_permits: 0, climate_neutral_felling: 0, co2: 0}

        setTotalArea(total.area)
        setTotalVolume(total.volume)

        setTotalSolarEnergy(total.solar_energy)
        setTotalSolarHa(total.solar_ha)

        //setTotalCO2(total.co2)
        setTotalEco(total.eco)
    }

    const downloadSBPPdf = async () => {
        setLoading(true)

        let ageChartElem = document.getElementById('age_chart');
        let treeChartElem = document.getElementById('tree_chart');

        ageChartElem.style.setProperty('width', '700px', 'important');
        treeChartElem.style.setProperty('width', '500px', 'important');

        let oldAgeChartClass = ageChartElem.className
        let oldTreeChartClass = treeChartElem.className

        ageChartElem.className = 'bg-white neutral-charcoal h-100'
        treeChartElem.className = 'bg-white neutral-charcoal'

        let ageChart = await html2canvas(ageChartElem).then(canvas => canvas.toDataURL('image/png'))
        let treeChart = await html2canvas(treeChartElem).then(canvas => canvas.toDataURL('image/png'))

        ageChartElem.className = oldAgeChartClass
        treeChartElem.className = oldTreeChartClass

        ageChartElem.style.setProperty('width', '100%')
        treeChartElem.style.setProperty('width', '100%')

        await getSBPPdf({alder_chart: ageChart, price_chart: null, tree_chart: treeChart}, propsContext.props.filter(p => p.active)).then(resp => {
            const downloadLink = document.createElement("a");
            downloadLink.href = resp.file;
            downloadLink.download = "sbp.pdf";
            downloadLink.click();
        })

        setLoading(false)
    }

    return <div className='position-relative p-3' style={{minHeight: '100vh'}}>
        <VBLoadingScreen zIndex={1035} show_loading={loading} className='position-absolute' />

        <PropselectorContainer className='sticky-top z-1040'>
            <PropSelector />
        </PropselectorContainer>

        <div className='row mb-3'>
            <div className='col-12 d-flex' style={{marginTop: '40px'}}>
                <h1 className='d-inline-flex me-3'>Överblick</h1>
                {propsContext.props.filter(p => p.active).length > 0 && propsContext.props.filter(p => p.active).length < 2 && window.location.pathname.includes('sudo') && <div className='d-inline-flex align-self-center'><button className="n-btn btn-forest-green btn-sm rounded py-1 px-2" onClick={(e) => downloadSBPPdf(e)}>Hämta Pdf</button></div>}
            </div>
        </div>

        {propsContext.props.filter(p => p.active).length < 1 ? <h2>Välj fastigheter ovanför för att visa data</h2> : 
        <>
            <div className='row mb-3'>
                <div className='col-12 col-md-6 col-xl-4 col-xxl-3 mb-3 mb-md-0'>
                    <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                        <h5 className="fw-medium">Totalvolym {propsContext.props.filter(p => p.active).length < 2 && <>
                            {totalVolume && !edit.volume && <img role="button" onClick={() => setEdit({...edit, volume: totalVolume})} src={Window.static_path + "img/icons/ui-edit.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                            {edit.volume && <img role="button" onClick={() => setEdit({...edit, volume: false})} src={Window.static_path + "img/icons/cross.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                        </>}</h5>
                        {edit.volume ?
                            <>
                                <VBInput type="number" initial={totalVolume} onChange={(val) => setEdit({...edit, volume: val})} />
                                <button className='n-btn btn-forest-green btn-sm rounded mt-2' onClick={() => changeVolume()}>Spara</button>
                            </>
                            : <h3><span className="fw-medium">{totalVolume ? totalVolume.toLocaleString('sv-SE') : <Spinner animation="border" role="status" />}</span> m³sk</h3>}
                    </div>
                </div>

                <div className='col-12 col-md-6 col-xl-4 col-xxl-3'>
                    <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                        <h5 className="fw-medium">Areal inklusive vatten</h5>
                        <h3><span className="fw-medium">{totalArea || totalArea == 0 ? totalArea.toLocaleString('sv-SE') : <Spinner animation="border" role="status" />}</span> ha</h3>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12'>
                    <div className='shadow rounded bg-neutral-cloud p-2 p-lg-4'>
                        <h5>Fastighetsvärde</h5>
                        <p>Priserna utgår från ditt uppmätta virkesförråd</p>
                        {valuationChartData && <LineChart data={{
                            labels: valuationChartData.years,
                            datasets: valuationChartData.line.map((itm) => {
                                return {
                                    label: "Pris",
                                    data: itm.data,
                                    backgroundColor: itm.color,
                                    borderWidth: 4,
                                    borderColor: itm.color,
                                    borderCapStyle: 'butt',
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    pointBorderColor: itm.color,
                                    pointBackgroundColor: '#fff',
                                    pointBorderWidth: 5,
                                    pointRadius: 10,
                                    pointHitRadius: 10,
                                    pointHoverRadius: 8,
                                    pointHoverBackgroundColor: '#fff',
                                    pointHoverBorderColor: itm.color,
                                    pointHoverBorderWidth: 5,
                                }
                            })
                        }} />}
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12 col-xl-6 position-relative mb-3 mb-md-0'>
                    <div className='shadow rounded bg-neutral-cloud h-100 p-2 p-lg-4'>
                        <h5 className='fw-medium'>Huggningsklasser</h5>
                        <p className='subtitle'>Uppskattad fördelning av olika ägoslag</p>


                        {fellingClassData && <Table striped>
                            <thead>
                                <tr className='bg-neutral-cloud-600'>
                                    <th className='fw-medium' scope="col">Huggningsklass</th>
                                    <th className='fw-medium' scope="col">Total areal (ha)</th>
                                    <th className='fw-medium' scope='col'>Total volym (m³sk)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fellingClassData.map(fellingClass => {
                                    return <tr>
                                        <td>{fellingClass.class}</td>
                                        <td>{fellingClass.area}</td>
                                        <td>{fellingClass.volume}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>}
                    </div>
                </div>

                <div className='col-12 col-xl-6'>
                    <div className='shadow rounded bg-neutral-stone p-2 p-lg-4'>
                        {totalSolarEnergy && totalSolarHa >= 5 ? <>
                            <h5 className="fw-medium">Solenergiproduktion</h5>
                            <p>Du har <span className="h5 fw-medium tertiary-main">{Math.round(totalSolarHa).toLocaleString('sv-SE')}</span> hektar lämplig för storskalig solenergiproduktion.</p>
                            <p className="mb-1">Estimerat arrendevärde:</p>
                            <h2 className="tertiary-main fw-medium">{Math.round(totalSolarHa * totalSolarEnergy.arrende_per_ha).toLocaleString('sv-SE')} <span className="h5">kr/år</span></h2>
                            <p className="mb-1">Uppskattad energiproduktion:</p>
                            <h2 className="tertiary-main fw-medium">{Math.round(totalSolarHa * totalSolarEnergy.solar_energy).toLocaleString('sv-SE')} <span className="h5">MWh/år</span></h2>

                            <p className="mb-1">Det motsvarar:</p>
                            <div className="d-flex align-items-center">
                                <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                <p className="fw-medium mb-0">{Math.round(totalSolarHa * totalSolarEnergy.mil_with_truck).toLocaleString('sv-SE')} mil med eldriven lastbil.</p>
                            </div>

                            <div className="d-flex align-items-center my-1">
                                <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                <p className="fw-medium mb-0">Tillverkning av {Math.round(totalSolarHa * totalSolarEnergy.approx_ton_paper).toLocaleString('sv-SE')} ton kontorspapper.</p>
                            </div>

                            <div className="d-flex align-items-center">
                                <img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="tertiary-main-filter me-1" />
                                <p className="fw-medium mb-0">Årsförbrukningen i {Math.round(totalSolarHa * totalSolarEnergy.approx_villas).toLocaleString('sv-SE')} villor.</p>
                            </div>
                            
                            <p className="subtitle mt-2 mb-0">Om du vill ha en mer nogrann bedömning eller har andra frågor, kontakta vår partner <a className="hover-primary-core fw-medium" onClick={() => {mixpanel.track("Clicked Recap mailto"); window.location.href="mailto:christer.boman@recap.se?subject=Jag vill veta mer om storskalig solenergiproduktion";}} href="#">Recap Energy (recapenergy.com)</a></p>
                        </> : <>
                            <h5 className="fw-medium">Potential för storskalig solenergiproduktion</h5>
                            <p className="fw-medium"><img src={`${Window.static_path}img/icons/lightning-charge.svg`} height="30px" className="supp-pine-filter me-1" />Nej</p>
                        </>}
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12 col-xl-6 d-flex flex-column'>
                    <div className='mb-3'>
                        <div className='shadow rounded bg-neutral-stone neutral-charcoal p-2 p-lg-4'>
                            <h5 className="fw-medium">Medelbonitet {propsContext.props.filter(p => p.active).length < 2 && <>
                                {avgGrowth && !edit.growth && <img role="button" onClick={() => setEdit({...edit, growth: avgGrowth})} src={Window.static_path + "img/icons/ui-edit.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                                {edit.growth && <img role="button" onClick={() => setEdit({...edit, growth: false})} src={Window.static_path + "img/icons/cross.svg"} className="charcoal-filter float-end" width="20px" height="20px" style={{maxHeight: '70vh'}} />}
                            </>}</h5>
                            {edit.growth ?
                                <>
                                    <VBInput type="number" initial={avgGrowth} onChange={(val) => setEdit({...edit, growth: val})} />
                                    <button className='n-btn btn-forest-green btn-sm rounded mt-2' onClick={() => changeGrowth()}>Spara</button>
                                </>
                                : <h3><span className="fw-medium">{avgGrowth ? Math.round(avgGrowth) : <Spinner animation="border" role="status" />}</span> m³sk/ha/år</h3>}
                        </div>
                    </div>
                    
                    <div className='d-flex flex-grow-1 mb-3 mb-md-0'>
                        {TGLdata && <div id="tree_chart" className='shadow rounded bg-neutral-cloud w-100 neutral-charcoal p-2 p-lg-4'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h5 className='fw-medium'>Trädslagsfördelning</h5>
                                    <p>Estimerad trädslagsfördelning</p>
                                </div>
                                <div className='me-2'>
                                    <p className='caption fw-medium'>Tall %<br /> Gran %<br /> Löv %</p>
                                </div>
                            </div>
                            
                            
                            <PieChart data={{
                                datasets: [
                                    {
                                        label: 'Trädslagsfördelning',
                                        data: [(Math.trunc(TGLdata.tall)), (Math.trunc(TGLdata.gran)), (Math.trunc(TGLdata.lov))],
                                        backgroundColor: [
                                            '#00ABAC',
                                            '#A2CCB7',
                                            '#EAEEED',
                                        ],
                                        borderColor: [
                                            '#00ABAC',
                                            '#A2CCB7',
                                            '#EAEEED',
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                            }} />

                            <ul className='d-flex flex-column' style={{ listStyle: "none" }}>
                                <li className='caption fw-medium'><Span bgColor="#00ABAC"></Span> &nbsp; Tall: {Math.trunc(TGLdata.tall)} %</li>
                                <li className='caption fw-medium'><Span bgColor="#C4D0CD"></Span> &nbsp; Gran: {Math.trunc(TGLdata.gran)} %</li>
                                <li className='caption fw-medium'><Span bgColor="#EAEEED"></Span> &nbsp; Löv: {Math.trunc(TGLdata.lov)} %</li>
                            </ul>
                        </div>}
                    </div>
                </div>

                <div className='col-12 col-xl-6'>
                    <div className='mb-3'>
                        <div className='shadow rounded bg-neutral-stone neutral-charcoal h-100 p-2 p-lg-4'>
                            <h5 className="fw-medium">Har du virke eller GROT att sälja? </h5>

                            <p>Vi hjälper dig ta in anbud!</p>
                            <p>✓ Kostnadsfritt – Vi tar betalt från skogsbolagen.</p>
                            <p>✓ Fri prövningsrätt och inga förbindelser.</p>
                            <p>✓ Nå ut till en bredare köparbas för det bästa priset.</p>
                            <p>✓ Dedikerat stöd och rådgivning under hela processen.</p>
                            <h5 className='fw-medium'><a href="/salj-virke/" className='supp-forest-green'>Klicka här för en prisförfrågan.</a></h5> 
                        </div>
                    </div>

                    <div className='d-flex flex-grow-1'>
                        <div class="shadow rounded bg-supp-pine w-100 p-2 p-lg-4">
                            {totalEco && totalEco.ha >= 1 ? <>
                                <h5 class="fw-medium primary-core">Kolkrediter</h5>
                                <p class="white">Vill du plantera träd på obeskogad mark och tjäna kolkrediter?</p>
                                <p class="white">Du har <span class="h5 fw-medium primary-core">{totalEco.ha.toLocaleString('sv-SE')}</span> hektar lämplig för kolkrediter.</p>

                                <p class="white">Det motsvarar ungefär:</p>
                                <div class="d-flex align-items-center">
                                    <img src="https://virkesborsen-files-staging.s3.amazonaws.com/static/img/icons/co2_euro.svg" height="30px" class="primary-core-filter me-3" />
                                    <h2 class="primary-core mb-0 me-1">{(totalEco.ha*totalEco.sek*250).toLocaleString('sv-SE')}</h2>
                                    <span class="h5 primary-core mb-0">SEK</span>
                                </div>
                                
                                <p class="white subtitle mt-2 mb-0">Vill du veta mer, <a href="https://virkesborsen.typeform.com/to/oFPxZU7v" target="_blank" onClick={() => mixpanel.track("Clicked EcoBase link")} class="primary-core hover-primary-core-200 fw-medium">klicka här!</a></p>
                            </> : <>
                                <h5 className="fw-medium primary-core">Potential för Kolrediter</h5>
                                <p className="fw-medium white"><img src={`${Window.static_path}img/icons/co2_euro.svg`} height="30px" className="primary-core-filter me-1" />Nej</p>
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-12'>
                    <div className='position-relative h-100'>
                        <div id="age_chart" className='shadow rounded bg-neutral-cloud neutral-charcoal h-100 p-2 p-lg-4'>                           
                            <div className='d-flex justify-content-between'>
                                <h5 className='fw-medium'>Åldersfördelning</h5>

                                <div className='caption'>
                                    <VBRadioGroup
                                        options={[{ value: 'area', label: "Areal (ha)" }, { value: 'volume', label: "Volym (m³sk)" }]}
                                        initial={ageGraphView}
                                        onChange={(val) => setAgeGraphView(val)} />
                                </div>
                            </div>

                            <div className='d-flex justify-content-between'>
                                <p>Estimerad åldersfördelning</p>

                                <div>
                                    <p className='caption fw-medium mb-0'>Y-axeln: {ageGraphView == 'area' ? 'Areal (ha)' : ageGraphView == 'volume' ? 'Volym (m³sk)' : ''}</p>
                                    <p className='caption fw-medium mb-0'>X-axeln: Ålder</p>
                                </div>
                            </div>

                            {areaAge && ageGraphView == 'area' ? <BarChart 
                                data={{
                                    labels: ["0-10", "10-20", "20-30", "30-40", "40-50", "50-60", "60-70", "70-80", "80-90", "90-100", "100+"],
                                    datasets: [
                                        {
                                            label: "Areal",
                                            data: areaAge,
                                            backgroundColor: '#A2CCB7',
                                            borderSkipped: false,
                                            borderWidth: 1,
                                            borderRadius: 50,
                                            categoryPercentage: 0.4,
                                            barPercentage: 1.0
                                        },
                                    ],
                                }}
                                y={{
                                    ticks: {
                                        color: '#1C332A',
                                        fontSize: 60,
                                        fontWeight: 'bold',
                                        beginAtZero: true,
                                        min: 0,
                                        max: 1000,
                                        stepSize: 10,
                                        grace: "0.005%",
                                        callback: function (value, index, ticks) {
                                            return value + " " + '-';
                                        },
                                    },
                                    grid: {
                                        drawBorder: false,
                                        display: false,
                                    },
                                }} /> : volumeAge && ageGraphView == 'volume' ? <BarChart 
                                data={{
                                    labels: ["0-10", "10-20", "20-30", "30-40", "40-50", "50-60", "60-70", "70-80", "80-90", "90-100", "100+"],
                                    datasets: [
                                        {
                                            label: "Volym",
                                            data: volumeAge,
                                            backgroundColor: '#037971',
                                            borderSkipped: false,
                                            borderWidth: 1,
                                            borderRadius: 50,
                                            categoryPercentage: 0.4,
                                            barPercentage: 1.0
                                        },
                                    ],
                                }} 
                                y={{
                                    ticks: {
                                        color: '#1C332A',
                                        fontSize: 60,
                                        fontWeight: 'bold',
                                        beginAtZero: true,
                                        min: 0,
                                        max: 3000,
                                        stepSize: 100,
                                        grace: "0.005%",
                                        callback: function (value, index, ticks) {
                                            return value + " " + '-';
                                        },
                                    },
                                    grid: {
                                        drawBorder: false,
                                        display: false,
                                    },
                                }}/>
                            : null}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-12 mb-5'>
                    <div className='shadow rounded bg-neutral-cloud neutral-charcoal overflow-auto p-2 p-lg-4' style={{ height: "500px" }}>
                        <h5 className='fw-medium'>Avdelningsbeskrivning</h5>
                        <p className='subtitle'>Sammanfattning av samtliga avdelningar</p>
                        <Table striped className="position-relative">
                            <thead className="position-sticky" style={{ top: "-30px", height: "60px" }}>
                                <tr className='bg-neutral-cloud-600'>
                                    {propsContext.props.filter(p => p.active).length > 1 ? <th className='fw-medium' scope="col">Fastighet </th> : null}
                                    <th className='fw-medium' scope="col">Avd # </th>
                                    <th className='fw-medium' scope="col">Area</th>
                                    <th className='fw-medium' scope='col'>Ålder</th>
                                    <th className='fw-medium' scope='col'>HKL</th>
                                    <th className='fw-medium' scope='col'>SI</th>
                                    <th className='fw-medium' scope='col'>Volym</th>
                                    <th className='fw-medium text-nowrap' scope='col'>T | G | L</th>
                                    <th className='fw-medium' scope='col'>Bonitet</th>
                                </tr>
                            </thead>

                            <tbody>
                                {propsContext.props.filter(p => p.active).map(prop => {
                                    if(prop.data) {
                                        return prop.data.sections.sort((a, b) => a.localID < b.localID ? -1 : a.localID > b.localID ? 1 : 0).map(i => {
                                            return <tr>
                                                {propsContext.props.filter(p => p.active).length > 1 ? <td>{prop.designation}</td> : null}
                                                <td>{i.localID}</td>
                                                <td>{i.area}</td>
                                                <td>{i.age}</td>
                                                <td>{i.felling_class}</td>
                                                <td>{i.si > 0 ? `${i.major_tree}${i.si}` : null}</td>
                                                <td>{i.volume}</td>
                                                <td className='text-nowrap'>{i.tall} | {i.gran} | {i.lov}</td>
                                                <td>{Math.round(i.growth_rate)}</td>
                                            </tr>
                                        })
                                    }
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>}

        {/* ERROR Modal */}
        <ErrorModal show={errorModal} title={errorModal?.title} message={errorModal?.message} onToggle={() => setErrorModal(false)} />
    </div>
}