import React from 'react';
import Navigation from '../../components/static/Navigation';
import Footer from '../../components/static/Footer';
import Cookies from 'js-cookie';

import data_map from './api';

class NHCreate extends React.Component {
    constructor() {
        super();
        this.navigation = React.createRef();

        this.state={
            active_tab: () => null,
            form: null
           
        }
    }

    componentDidMount(){
        
    }

    setVal(val, name, data_set){
        if(!this.state.hasOwnProperty(data_set)){
            this.state[data_set] = {}
        }
        this.setState({
            [data_set]:{...this.state[data_set], [name]:val}
        });
    }


    generateCreateForm(name){
        return <div className="row">
                <div className="col-12 col-md-6">
            {Object.keys(data_map[name].fields).map((key) => {
                let initial = null;
                let data = data_map[name].fields[key]
                let get_input_args =[data.label, initial, (val) => {this.setVal(val, key, name)}]
                if(data.window_options){
                    
                    get_input_args.push(Window.react_data[data.window_options])
                    console.log(get_input_args)
                }
                return <>{data.input.get_input(...get_input_args)} <br/></>
            })}
            <button className="btn btn-sm btn-forest-green" onClick={() => {this.handleCreate(name)}}>Create</button>
        </div></div>
    }

    handleCreate(name){
        const formData = new FormData();
        let req_data = {}
        let data = data_map[name];
        let url = data["create_url"]
        let headers = {
            'credentials': 'same-origin',
            'X-CSRFToken': Cookies.get('csrftoken')
            }
        if(data["contentType"]){
            headers["Content-Type"] = data["contentType"];
        }

        
        Object.keys(data["fields"]).map((key) => {
            let val = this.state[name][key]
            if(data["formatters"]?.hasOwnProperty(key)){
                val = data["formatters"][key]["output"](val);
            }

            formData.append(key, val);
            req_data[key] = val
        })

        fetch(url,  {
            method:"POST",
            headers: headers,
            body: (data["contentType"] ? JSON.stringify(req_data) : formData)
        }).then(resp => resp.json()).then((json) =>{
            alert(json)
        })
    
    }


    render() {
        return (
            <div className="container bg-neutral-stone-100 py-5">
                <h3 className="mt-3">Skapa annons</h3>
                <div className="d-flex mb-3">
                    <p style={{cursor:"pointer"}} className="text-decoration-underline me-3 mb-0" onClick={() => {this.setState({form:this.generateCreateForm("project")})}}>Create Project</p>
                </div>
                
                {this.state.active_tab()}
                {this.state.form}
                
            </div >
        )
    }
}
export default NHCreate;