import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';

import styled from 'styled-components';

const PieRoot = styled.div`
    canvas {
        max-height: 280px;
        @media (min-width: 1200px) {
            max-height: 320px;
        }
    }
`

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
function PieChart({ data, options }) {

    return <PieRoot>
        <Doughnut
            data={data}
            options={options} />
    </PieRoot>
}

PieChart.defaultProps = {
    options: {
        plugins: {
            tooltip: {
                enabled: true
            },
            datalabels: {
                color: 'black',
            },
        },
        legend: {
            display: false,
        },
    },
}

export default PieChart;