import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";

import Modal from 'react-bootstrap/Modal';

import PropSelector from './comps/PropSelector';
import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/new/vbLeaflet';
import { swapCoords } from '@virkesborsen/vb-js-commons/lib/components/new/leafletConfig';

import { PropsData, VisaValda } from './context';
import { fireRiskColors, beetleRiskColors, PropselectorContainer, ToolboxContainer, MapTools } from './helpers';

import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import VBLoadingScreen from '@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen';

const RootContainer = styled.div`
    height: calc(${props => props.viewportHeight}px - 80px);
    @media (min-width: 992px) {
        height: ${props => props.viewportHeight}px;
    }
`

export default function Risker() {
    const propsContext = React.useContext(PropsData);
    const visaValdaContext = React.useContext(VisaValda);

    const leaflet = React.useRef(null);

    const [view, setView] = React.useState('beetle');
    const [loading, setLoading] = React.useState(true);
    const [viewportHeight, setViewportHeight] = React.useState(window.innerHeight);

    const [legend, setLegend] = React.useState({fire: [], beetle: []});
    const [showLegend, setShowLegend] = React.useState(true);

    // Modals
    const [markerModal, setMarkerModal] = React.useState({show: false});
    const [deleteMarkerModal, setDeleteMarkerModal] = React.useState(false);

    const waitForMapThenSetData = async () => {
        const activeProps = propsContext.props.filter(p => p.active)

        if(activeProps.filter(p => p.data).length != activeProps.length || activeProps.filter(p => p.border).length != activeProps.filter(p => p.type == 'PROP').length) {
            setTimeout(() => waitForMapThenSetData(), 1000)
        } else {
            if(!leaflet.current.state.map) {
                setTimeout(() => waitForMapThenSetData(), 100)
            } else {
                setMapData(true)
                setLegend(generateLegend())
                setLoading(false)
            }
        }
    }

    const generateLegend = () => {
        let fire = new Set()
        let beetle = new Set()
        propsContext.props.filter(p => p.active).map(p => {
            if('data' in p) {
                p.data.legends.fire_risks.map(c => fire.add(c))
                p.data.legends.beetle_risks.map(c => beetle.add(c))
            }
        })
        return {fire: Array.from(fire), beetle: Array.from(beetle)}
    }
    
    React.useEffect(() => {
        waitForMapThenSetData()

        // This is for safari users to fix a weird scrolling behaviour from Safari itself
        document.body.classList.add('fixed-top');
        // This is for the URL bar in mobile browsers for when the appears/dissapears and the viewport of the window changes.
        window.addEventListener('resize', () => setViewportHeight(window.innerHeight));

        return () => {
            document.body.classList.remove('fixed-top');
            window.removeEventListener('resize', () => setViewportHeight(window.innerHeight));
        }
    }, []);

    React.useEffect(() => {
        if(visaValdaContext.visaValda) {
            setLegend(generateLegend())
            setMapData()
            visaValdaContext.setVisaValda(false)
        }
    }, [visaValdaContext.visaValda]);

    React.useEffect(() => {
        if(leaflet.current.state.map) {
            setMapData(false)
        }
    }, [view]);

    const fireRisksOrder = ['Extremt stor risk', 'Mycket stor risk', 'Måttlig risk', 'Stor risk', 'Liten risk', 'Mycket liten risk', 'Ingen prognos']

    const beetleRisksOrder = ['Hög risk', 'Medel risk', 'Liten risk', 'Ingen prognos']

    const mapMarkers = [
        `${Window.static_path}img/map-markers/marker-location.svg`,
        `${Window.static_path}img/map-markers/marker-mushroom.svg`,
        `${Window.static_path}img/map-markers/marker-tree.svg`,
        `${Window.static_path}img/map-markers/marker-stop.svg`,
        `${Window.static_path}img/map-markers/marker-windy.svg`,
        `${Window.static_path}img/map-markers/marker-treebug.svg`,
        `${Window.static_path}img/map-markers/marker-onfire.svg`
    ]

    const markerPopupHtml = (marker, propID, propType) => {
        const html = document.createElement("div")
        html.innerHTML = `
            <b>${marker.name}</b><br />
            ${marker.comment}<br />
        `

        const editButton = document.createElement('span')
        editButton.role = 'button'
        editButton.classList.add('text-decoration-underline', 'me-2');
        editButton.innerHTML = 'Ändra'
        editButton.onclick = function() {
            setMarkerModal({show: true, selected: marker.icon, name: marker.name, comment: marker.comment, id: marker.id, ref: propID, type: propType })
        }
        const deleteButton = document.createElement('span')
        deleteButton.classList.add('highlight', 'text-decoration-underline');
        deleteButton.role = 'button'
        deleteButton.innerHTML = 'Radera'
        deleteButton.onclick = function() {
            setDeleteMarkerModal({id: marker.id, ref: propID, type: propType})
        }

        html.appendChild(editButton)
        html.appendChild(deleteButton)

        return html
    }

    const setMapData = (zoomToProps=true) => {
        const activeProps = propsContext.props.filter(p => p.active)

        let map = leaflet.current.state.map
        let sections = L.featureGroup([])
        let markers = L.featureGroup([])
        let borders = L.featureGroup([])

        leaflet.current.clearMap()

        activeProps.map(prop => {
            // Creating property borders
            if('border' in prop) {
                prop.border.map(b => {
                    let layer = L.polygon(swapCoords(b), {color: "#FFFFFF", fillOpacity: 0, interactive: false, type: 'polygon'})
                    leaflet.current.setLayer(layer)
                    layer.addTo(borders)
                })
            }

            // Creating all the markers for the property
            prop.data.markers.map(m => {
                var icon = L.icon({
                    iconUrl: m.icon,
                    iconSize: [40, 57], // size of the icon
                    shadowSize: [40, 57], // size of the shadow
                    iconAnchor: [20, 57], // point of the icon which will correspond to marker's location (actual length divide by 2)
                    shadowAnchor: [40, 57],  // the same for the shadow
                    popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
                })

                let mark = L.marker([m.y, m.x], { icon: icon, type: 'marker' })
                mark.data = { djangoID: m.id, ref: prop.ref, type: prop.type }

                mark.bindPopup(markerPopupHtml(m, prop.ref, prop.type))

                mark.addTo(markers)
            })

            leaflet.current.setLayers(markers)

            // Creating section polygons for property
            prop.data.sections.map(sect => {
                let color = "#bb0000"
                
                let beetleColor = beetleRiskColors[sect.risks.beetle]
                let fireColor = fireRiskColors[sect.risks.fire]
                if(view == 'beetle') {
                    color = beetleColor
                } else if(view == 'fire') {
                    color = fireColor
                }

                let baseStyle = {
                    color: color,
                    fillColor: color,
                    opacity: 1,
                    fillOpacity: 0.5,
                    weight: 2,
                    type: 'polygon'
                }
                let layer = L.polygon(swapCoords(sect.geom.coordinates), baseStyle)

                layer.baseStyle = baseStyle
                
                layer.on('mouseover', () => {
                    layer.setStyle({
                        color: 'white',
                        weight: 3.3,
                        fillOpacity: 0.8,
                        dashArray: '10, 10'
                    })

                    layer.bringToFront()
                })

                layer.on('mouseout', () => {
                    layer.setStyle(baseStyle)
                    borders.bringToFront()
                })

                layer.addTo(sections)
            })
        })

        leaflet.current.setLayers(sections)

        borders.addTo(map)
        markers.addTo(map)
        sections.addTo(map)

        borders.bringToFront()

        if(activeProps.length > 0 && zoomToProps) {
            map.fitBounds(sections.getBounds());
        }
    }

    const handleErrorRequest = (resp) => {
        if(resp.status == 500) {
            setLoading(false)
            setErrorModal(true)
            throw new Error('Oops something went wrong');
        } else {
            return resp.json()
        }
    }

    const updateMarker = () => {
        setLoading(true)

        fetch("/map-api/user-update-marker/", {
            method: "POST",
            body: JSON.stringify({
                propID: markerModal.ref,
                propType: markerModal.type,
                markerId: markerModal.id,
                markerName: markerModal.name,
                markerComment: markerModal.comment,
                markerIcon: markerModal.selected
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => handleErrorRequest(resp)).then(data => {
            let props = propsContext.props
            props.find(p => p.ref == data.ref).data = data.new_data
            propsContext.setProps(props)
            setMarkerModal({show: false})
            setLoading(false)
            setMapData(false)
        });
    }

    const deleteMarker = async (id, ref, type) => {
        return fetch("/map-api/user-delete-marker/", {
            method: "POST",
            body: JSON.stringify({
                propID: ref,
                propType: type,
                markerId: id,
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => handleErrorRequest(resp)).then((data) => {
            let props = propsContext.props
            props.find(p => p.ref == data.ref).data = data.new_data
            propsContext.setProps(props)
        });
    }

    return <RootContainer className='position-relative overflow-hidden' viewportHeight={viewportHeight}>
        <VBLoadingScreen zIndex={1035} show_loading={loading} className='position-absolute' />

        <VBLeaflet ref={leaflet} id='map_risker' baseMap='Mapbox Satelite' />
        
        <PropselectorContainer className='position-absolute z-1040'>
            <PropSelector />
        </PropselectorContainer>

        <ToolboxContainer className='position-absolute z-1010'>
            <div className='d-flex flex-column bg-neutral-cloud shadow rounded p-1'>
                <div role='button' onClick={() => leaflet.current.zoomIn()} className='d-flex justify-content-center align-items-center bg-white hover-bg-supp-forest-green-300 rounded-1 mb-1' style={{height: '30px'}}>
                    <img src={`${Window.static_path}img/icons/plus.svg`} height='20px' width='20px' />
                </div>
                <div role='button' onClick={() => leaflet.current.zoomOut()} className='d-flex justify-content-center align-items-center bg-white hover-bg-supp-forest-green-300 rounded-1 mb-3' style={{height: '30px'}}>
                    <img src={`${Window.static_path}img/icons/minus.svg`} height='30px' width='30px' />
                </div>

                <div role='button' onClick={() => setShowLegend(!showLegend)} className={`d-flex justify-content-center align-items-center ${showLegend ? 'bg-supp-forest-green' : 'bg-white hover-bg-supp-forest-green-300'} rounded-1`} style={{height: '30px'}}>
                        <img src={`${Window.static_path}img/icons/info_filled.svg`} className={showLegend ? 'white-filter' : ''} height='20px' width='20px' />
                    </div>
            </div>
        </ToolboxContainer>

        <div className={`${showLegend ? 'd-flex' : 'd-none'} flex-column bg-neutral-cloud shadow rounded z-1010 position-absolute p-1 mt-3`} style={{width: '250px', right: '10px', bottom: '120px'}}>
            <div className='bg-white caption p-2'>
                {view == 'fire' && <>
                    <span className='fw-bold mb-1'>Brand Risker</span>
                    {legend.fire.sort((a, b) => fireRisksOrder.indexOf(a) == -1 ? 1 : fireRisksOrder.indexOf(b) == -1 ? -1 : fireRisksOrder.indexOf(a) - fireRisksOrder.indexOf(b)).map(i => {
                        return <div className='d-flex mb-1'>
                            <div className='me-1' style={{backgroundColor: fireRiskColors[i] ? fireRiskColors[i] : '#808080', height: '16px', minWidth: '16px'}}></div>{i}
                        </div>
                    })}
                </>}

                {view == 'beetle' && <>
                    <span className='fw-bold mb-1'>Granbarkborre Risker</span>
                    {legend.beetle.sort((a, b) => beetleRisksOrder.indexOf(a) == -1 ? 1 : beetleRisksOrder.indexOf(b) == -1 ? -1 : beetleRisksOrder.indexOf(a) - beetleRisksOrder.indexOf(b)).map(i => {
                        return <div className='d-flex mb-1'>
                            <div className='me-1' style={{backgroundColor: beetleRiskColors[i] ? beetleRiskColors[i] : '#808080', height: '16px', minWidth: '16px'}}></div>{i}
                        </div>
                    })}
                </>}
            </div>
        </div>

        <MapTools className='position-fixed z-1010 show'>
            <div className='d-flex flex-column bg-neutral-cloud shadow rounded-top caption p-2'>
                <div className='text-center fw-bold mb-1'>Du ser nu {view == 'beetle' ? 'granbarkborre' : 'brand'} risk</div>
                
                <div className='d-flex justify-content-center'>
                    <div role='button' onClick={() => setView('fire')} className={`d-flex flex-column align-items-center justify-content-center rounded bg-white me-2 ${view == 'fire' ? 'bg-supp-forest-green' : 'hover-bg-supp-forest-green-300'}`} style={{width: '60px', height: '60px'}}>
                        <img src={`${Window.static_path}img/map-markers/onfire.svg`} className={`mb-1 ${view == 'fire' ? 'white-filter' : ''}`} height="30px" width="30px" />
                    </div>

                    <div role='button' onClick={() => setView('beetle')} className={`d-flex flex-column align-items-center justify-content-center rounded bg-white ${view == 'beetle' ? 'bg-supp-forest-green' : 'hover-bg-supp-forest-green-300'}`} style={{width: '60px', height: '60px'}}>
                        <img src={`${Window.static_path}img/map-markers/treebug.svg`} className={`mb-1 ${view == 'beetle' ? 'white-filter' : ''}`} height="30px" width="30px" />
                    </div>

                    
                </div>
            </div>
        </MapTools>

        {/* MARKER EDIT POPUP */}
        <Modal show={markerModal.show} onHide={() => setMarkerModal({show: false})} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h2 className="neutral-charcoal mb-0 n-h4">Redigera Markör</h2>
            </Modal.Header>
            <Modal.Body className="neutral-charcoal py-0">
                <p>Markörer hjälper dig hålla kolla på viktiga punkter i din skog.</p>
                <p className="mb-1">Välj ikon:</p>

                {markerModal.error && <p className="text-danger">{markerModal.error}</p>}

                {mapMarkers.map(m => <div role='button' onClick={() => setMarkerModal({...markerModal, selected: m})} className={`m-2 d-inline-flex flex-justify-center flex-align-center justify-content-center rounded ${markerModal.selected == m ? 'bg-neutral-cloud-700' : 'hover-bg-neutral-cloud-700'}`} style={{ width: "64px", height: "72px", transition: ".3s" }}>
                        <img src={m} className="p-1" width="48px" />
                    </div>
                )}

                <p className="mb-1 mt-2">Namn</p>
                <VBInput
                    initial={markerModal?.name}
                    onChange={(val) => markerModal.name = val} />

                <p className="mb-1 mt-2">Beskrivning</p>
                <VBTextarea
                    initial={markerModal?.comment}
                    onChange={(val) => markerModal.comment = val} 
                    resize="none" 
                    height="200px" />
            </Modal.Body>
            <Modal.Footer className="justify-content-between border-0">
                <button className='n-btn btn-forest-green rounded btn-sm w-100 my-2' onClick={() => {
                    if(!markerModal.selected) {
                        setMarkerModal({...markerModal, error: '*Vänligen välj en markör innan du fortsätter.'})
                    } else {
                        updateMarker()
                    }
                }}>
                    {markerModal.id ? 'Spara' : 'Välj plats på kartan'}
                </button>

                {markerModal.id && <button className='n-btn btn-forest-green rounded btn-outline btn-sm w-100 my-2' onClick={() => setDeleteMarkerModal({id: markerModal.id, ref: markermModal.ref})}>Radera</button>}
            </Modal.Footer>
        </Modal>

        {/* CONFIRM MARKER DELETE Modal */}
        <Modal show={deleteMarkerModal} onHide={() => setDeleteMarkerModal(false)} size="md" contentClassName="bg-neutral-cloud p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h3 className="neutral-charcoal mb-0 n-h4">Är du säker på att du vill radera?</h3>
            </Modal.Header>
            <Modal.Footer className="justify-content-between border-0">
                <button type="button" className="n-btn btn-forest-green btn-sm" onClick={() => setDeleteMarkerModal(false)}>Nej</button>
                <button type="button" className="n-btn btn-forest-green btn-sm" onClick={() => {
                    setLoading(true)
                    deleteMarker(deleteMarkerModal.id, deleteMarkerModal.ref).then(() => {
                        setDeleteMarkerModal(false)
                        setMapData(false)
                        setLoading(false)
                    })
                }}>Radera</button>
            </Modal.Footer>
        </Modal>
    </RootContainer>
}