import React from 'react';
import Cookies from 'js-cookie';
import styled from "styled-components";
import pointInPolygon from 'point-in-polygon';

import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';

import PropSelector from './comps/PropSelector';
import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/new/vbLeaflet';
import { swapCoords } from '@virkesborsen/vb-js-commons/lib/components/new/leafletConfig';

import { PropsData, VisaValda } from './context';
import { SiValues, FellingClasses, FellingClassesLegend, PropselectorContainer, ToolboxContainer, MapTools, setUserLocation } from './helpers';
import { ErrorModal } from '../../modals';

import VBInput from '@virkesborsen/vb-js-commons/lib/components/new/vbInput';
import VBTextarea from '@virkesborsen/vb-js-commons/lib/components/new/vbTextarea';
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';
import VBLoadingScreen from '@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen';

const SectionPopup = styled(Offcanvas)`
    transition: left .3s ease, transform .3s ease;
    left: 0px !important;

    &.show {
        @media (min-width: 576px) {
            left: 270px !important;
        }
    }
`

const CustomScrollbar = styled.div`
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #023436;
        border-radius: 10px;
    }
`

const RootContainer = styled.div`
    height: calc(${props => props.viewportHeight}px - 80px);
    @media (min-width: 992px) {
        height: ${props => props.viewportHeight}px;
    }
`

export default function Karta() {
    const propsContext = React.useContext(PropsData);
    const visaValdaContext = React.useContext(VisaValda);

    const leaflet = React.useRef(null);

    const [refresh, doRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [viewportHeight, setViewportHeight] = React.useState(window.innerHeight);

    const [mapTools, setMapTools] = React.useState(false);
    const [sectionPopup, setSectionPopup] = React.useState(false);
    const [showSectNr, setShowSectNr] = React.useState(false);
    const [legend, setLegend] = React.useState([]);
    const [showLegend, setShowLegend] = React.useState(false);

    // Modals
    const [infoModal, setInfoModal] = React.useState(false);
    const [markerModal, setMarkerModal] = React.useState({show: false});
    const [selectPropModal, setSelectPropModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState(false);
    const [deleteMarkerModal, setDeleteMarkerModal] = React.useState(false);
    
    const waitForMapThenSetData = async () => {
        const activeProps = propsContext.props.filter(p => p.active)

        if(activeProps.filter(p => p.data).length != activeProps.length || activeProps.filter(p => p.border).length != activeProps.filter(p => p.type == 'PROP').length) {
            setTimeout(() => waitForMapThenSetData(), 100)
        } else {
            if(!leaflet.current.state.map) {
                setTimeout(() => waitForMapThenSetData(), 100)
            } else {
                setLoading(false)
                setMapData(true)
                setLegend(generateLegend())
            }
        }
    }

    const generateLegend = () => {
        let legend = new Set()
        propsContext.props.filter(p => p.active).map(p => {
            if('data' in p) {
                p.data.legends.felling_classes.map(c => legend.add(c))
            }
        })
        return Array.from(legend)
    }
    
    React.useEffect(() => {
        waitForMapThenSetData()

        // This is for safari users to fix a weird scrolling behaviour from Safari itself
        document.body.classList.add('fixed-top');
        // This is for the URL bar in mobile browsers for when the appears/dissapears and the viewport of the window changes.
        window.addEventListener('resize', () => setViewportHeight(window.innerHeight));

        return () => {
            document.body.classList.remove('fixed-top');
            window.removeEventListener('resize', () => setViewportHeight(window.innerHeight));
        }
    }, []);

    React.useEffect(() => {
        if(visaValdaContext.visaValda) {
            setSectionPopup(false)
            setLegend(generateLegend())
            setMapData()
            visaValdaContext.setVisaValda(false)
        }
    }, [visaValdaContext.visaValda]);

    React.useEffect(() => {
        if(refresh) {
            doRefresh(false)
        }    
    }, [refresh]);

    React.useEffect(() => {
        if(leaflet.current.state.map) {
            setMapData(false)
        }
    }, [showSectNr]);

    const fellingClassesOrder = ['S1', 'S2', 'G1', 'G2', 'R', 'K', 'IA', 'R1']

    const mapMarkers = [
        `${Window.static_path}img/map-markers/marker-location.svg`,
        `${Window.static_path}img/map-markers/marker-mushroom.svg`,
        `${Window.static_path}img/map-markers/marker-tree.svg`,
        `${Window.static_path}img/map-markers/marker-stop.svg`,
        `${Window.static_path}img/map-markers/marker-windy.svg`,
        `${Window.static_path}img/map-markers/marker-treebug.svg`,
        `${Window.static_path}img/map-markers/marker-onfire.svg`
    ]

    const markerPopupHtml = (marker, propID, propType) => {
        const html = document.createElement("div")
        html.innerHTML = `
            <b>${marker.name}</b><br />
            ${marker.comment}<br />
        `

        const editButton = document.createElement('span')
        editButton.role = 'button'
        editButton.classList.add('text-decoration-underline', 'me-2');
        editButton.innerHTML = 'Ändra'
        editButton.onclick = function() {
            setMarkerModal({show: true, selected: marker.icon, name: marker.name, comment: marker.comment, id: marker.id, ref: propID, type: propType })
        }
        const deleteButton = document.createElement('span')
        deleteButton.classList.add('highlight', 'text-decoration-underline');
        deleteButton.role = 'button'
        deleteButton.innerHTML = 'Radera'
        deleteButton.onclick = function() {
            setDeleteMarkerModal({id: marker.id, ref: propID, type: propType})
        }

        html.appendChild(editButton)
        html.appendChild(deleteButton)

        return html
    }

    const setMapData = (zoomToProps=true) => {
        const activeProps = propsContext.props.filter(p => p.active)

        let selected = null;

        let map = leaflet.current.state.map
        let sections = L.featureGroup([])
        let markers = L.featureGroup([])
        let borders = L.featureGroup([])
        let labels = L.featureGroup([])

        leaflet.current.clearMap()
        
        activeProps.map(prop => {
            // Creating property borders
            if('border' in prop) {
                prop.border.map(b => {
                    let layer = L.polygon(swapCoords(b), {color: "#FFFFFF", fillOpacity: 0, interactive: false, type: 'polygon'})
                    leaflet.current.setLayer(layer)
                    layer.addTo(borders)
                })
            }

            // Creating all the markers for the property
            prop.data.markers.map(m => {
                var icon = L.icon({
                    iconUrl: m.icon,
                    iconSize: [40, 57], // size of the icon
                    shadowSize: [40, 57], // size of the shadow
                    iconAnchor: [20, 57], // point of the icon which will correspond to marker's location (actual length divide by 2)
                    shadowAnchor: [40, 57],  // the same for the shadow
                    popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
                })

                let mark = L.marker([m.y, m.x], { icon: icon, type: 'marker' })
                mark.data = { djangoID: m.id, ref: prop.ref, type: prop.type }

                mark.bindPopup(markerPopupHtml(m, prop.ref, prop.type))

                mark.addTo(markers)
            })

            leaflet.current.setLayers(markers)

            // Creating section polygons for property
            prop.data.sections.map(sect => {
                let color = FellingClassesLegend[sect.felling_class] ? FellingClassesLegend[sect.felling_class].color : "#bb0000"
                let baseStyle = {
                    color: color,
                    fillColor: color,
                    opacity: 1,
                    fillOpacity: 0.5,
                    weight: 2,
                    type: 'polygon'
                }
                let layer = L.polygon(swapCoords(sect.geom.coordinates), baseStyle)

                layer.baseStyle = baseStyle
                layer.data = {nr: sect.nr, localID: sect.localID, ref: prop.ref}

                layer.on('mouseover', () => { 
                    if(selected != layer) {
                        layer.setStyle({
                            color: 'white',
                            weight: 3.3,
                            fillOpacity: 0.8,
                            dashArray: '10, 10'
                        })
                    }
                    layer.bringToFront()
                })

                layer.on('mouseout', () => {
                    if(selected != layer) {
                        layer.setStyle(baseStyle)
                        if(selected) {
                            selected.bringToFront()
                        }
                    }
                    borders.bringToFront()
                })

                layer.on('click', () => {
                    if(leaflet.current.state.mode == 'view') {
                        setSectionPopup({...sect, designation: prop.designation, ref: prop.ref, layer: layer, edit: {}, errors: {}})
                        doRefresh(true)
                        if(selected) {
                            selected.setStyle(selected.baseStyle)
                        }
    
                        selected = layer
    
                        // Bring the clicked layer to the front
                        layer.bringToFront();
                        layer.setStyle({
                            fillColor: color,
                            fillOpacity: 0.8,
                            weight: baseStyle.weight + 1.5,
                            color: 'white',
                            dashArray: null
                        })
                    }
                })

                if(showSectNr) {
                    let label = L.tooltip(L.latLng(sect.label_location), {
                        drawn: true,
                        content: `${sect.localID}`,
                        className: 'white bg-transparent border-0 shadow-none',
                        direction: "center",
                        permanent: true,
                        opacity: 1
                    })
                    
                    label.addTo(labels);
                }

                layer.addTo(sections)
            })

            leaflet.current.setLayers(sections)

            borders.addTo(map)
            markers.addTo(map)
            sections.addTo(map)
            if(showSectNr) {
                labels.addTo(map)
            }
        })

        borders.bringToFront()
        if(activeProps.length > 0 && zoomToProps) {
            map.fitBounds(sections.getBounds());
        }
    }

    const handleErrorRequest = (resp) => {
        if(resp.status == 500) {
            setLoading(false)
            setErrorModal(true)
            throw new Error('Oops something went wrong');
        } else {
            return resp.json()
        }
    }

    // REST API's
    const createAdFromSection = () => {
        fetch("/map-api/create-ad/", {
            method: "POST",
            body: JSON.stringify({ sectID: sectionPopup.nr }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => handleErrorRequest(resp)).then(data => {
            Cookies.set(`ad_id_${Window.user_data.loggedInUserID}`, data.ad_id);
            window.location = "/auctions/create";
        });
    }

    const updateMarker = () => {
        setLoading(true)

        fetch("/map-api/user-update-marker/", {
            method: "POST",
            body: JSON.stringify({
                propID: markerModal.ref,
                propType: markerModal.type,
                markerId: markerModal.id,
                markerName: markerModal.name,
                markerComment: markerModal.comment,
                markerIcon: markerModal.selected
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => handleErrorRequest(resp)).then(data => {
            let props = propsContext.props
            props.find(p => p.ref == data.ref).data = data.new_data
            propsContext.setProps(props)
            setMarkerModal({show: false})
            setLoading(false)
            setMapData(false)
        });
    }

    const shapeCreateSubmit = (e, propID, propType) => {
        setLoading(true)

        if (e.layerType == 'marker') {
            fetch("/map-api/user-create-marker/", {
                method: "POST",
                body: JSON.stringify({
                    propID: propID,
                    propType: propType,
                    geom: e.layer.toGeoJSON(),
                    name: markerModal.name ? markerModal.name : '',
                    comment: markerModal?.comment ? markerModal.comment : '',
                    icon: markerModal.selected
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
            }).then(resp => handleErrorRequest(resp)).then((data) => {
                let props = propsContext.props
                props.find(p => p.ref == propID).data = data
                propsContext.setProps(props)
                setMarkerModal({show: false})
                setMapData(false)
                setLoading(false)
            });
        } else {
            fetch("/map-api/user-create-sections/", {
                method: "POST",
                body: JSON.stringify({ propID: propID, propType: propType, geom: e.layer.toGeoJSON() }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
            }).then(resp => handleErrorRequest(resp)).then((data) => {
                if("to_big" in data) {
                    setLoading(false)
                    setErrorModal({message: "Du har ritat en för stor avdelning"})
                    leaflet.current.state.map.removeLayer(e.layer)
                } else {
                    let props = propsContext.props
                    props.find(p => p.ref == propID).data = data
                    propsContext.setProps(props)

                    setMapData(false)
                    setLoading(false)
                }
            });
        }
    }

    const handleShapeEdit = () => {
        setLoading(true)
        let geoms = {features: [], type: 'FeatureCollection'};

        leaflet.current.state.editingLayers.eachLayer(l => {
            let layer = l.toGeoJSON()
            layer.properties = l.data
            geoms.features.push(layer)
        })

        fetch("/map-api/user-edit-sections/", {
            method: "POST",
            body: JSON.stringify({ geoms: geoms }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => handleErrorRequest(resp)).then((data) => {
            let props = propsContext.props
            data.map(i => props.find(p => p.ref == i.ref).data = i.new_data)
            propsContext.setProps(props)
            setLoading(false)
        });
    }

    const deleteMarker = async (id, ref, type) => {
        return fetch("/map-api/user-delete-marker/", {
            method: "POST",
            body: JSON.stringify({
                propID: ref,
                propType: type,
                markerId: id,
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => handleErrorRequest(resp)).then((data) => {
            let props = propsContext.props
            props.find(p => p.ref == data.ref).data = data.new_data
            propsContext.setProps(props)
        });
    }

    const handleShapeDelete = async () => {
        setLoading(true)

        let sections = []
        let markers = []

        leaflet.current.state.deletedLayers.eachLayer(l => {
            console.log('l', l)
            if(l.options.type == 'polygon') {
                sections.push(l.data.nr)
            } else if(l.options.type == 'marker') {
                console.log('l data', l.data)
                markers.push({id: l.data.djangoID, ref: l.data.ref, type: l.data.type})
            }
        })

        let requests = []

        if(sections.length > 0) {
            requests.push(fetch("/map-api/user-delete-sections/", {
                method: "POST",
                body: JSON.stringify({ localIDs: sections }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
            }).then(resp => handleErrorRequest(resp)).then(data => {
                let props = propsContext.props
                data.map(i => props.find(p => p.ref == i.ref).data = i.new_data)
                propsContext.setProps(props)
            }))
        }
        
        if(markers.length > 0) {
            markers.map(async m => {
                requests.push(deleteMarker(m.id, m.ref, m.type))
            })
        }

        Promise.all(requests).then(() => {
            setMapData(false)
            setLoading(false)
        })
    }

    const updateSectionData = () => {
        if (!('tgl' in sectionPopup.errors)) {
            setLoading(true)
            fetch("/map-api/user-update-sections/", {
                method: "POST",
                body: JSON.stringify({...sectionPopup.edit, nr: sectionPopup.nr, propID: sectionPopup.ref}),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
            }).then(resp => handleErrorRequest(resp).then(data => {
                setLoading(false)
                let props = propsContext.props
                props.find(p => p.ref == data.ref).data = data.new_data
                propsContext.setProps(props)
                setSectionPopup({...sectionPopup, enableEdit: false, ...sectionPopup.edit})
            }))
        }
    }

    const saveNote = () => {
        setLoading(true)

        fetch("/map-api/user-update-sections/", {
            method: "POST",
            body: JSON.stringify({
                nr: sectionPopup.nr,
                note: sectionPopup.note
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        }).then(resp => handleErrorRequest(resp)).then(data => {
            setLoading(false)
            let props = propsContext.props
            props.find(p => p.ref == data.ref).data = data.new_data
            propsContext.setProps(props)
            setMapData(false)
        })
    }

    // Tools
    const updateTGL = (val, type) => {
        let tgl = {
            tall: sectionPopup.edit.tall ? sectionPopup.edit.tall : sectionPopup.tall,
            gran: sectionPopup.edit.gran ? sectionPopup.edit.gran : sectionPopup.gran,
            lov: sectionPopup.edit.lov ? sectionPopup.edit.lov : sectionPopup.lov
        }
        tgl[type] = val;

        let data = sectionPopup
        data.edit = {...data.edit, ...tgl}

        validateTGL(tgl.tall, tgl.gran, tgl.lov) ? data.errors = {...data.errors, tgl: null} : data.errors = {...data.errors, tgl: 'Summan av TGL måste vara 100'}

        setSectionPopup(data)
        doRefresh(true)
    }

    const validateTGL = (tall, gran, lov) => {
        let sum = parseInt(tall) + parseInt(gran) + parseInt(lov);
        return sum === 100
    }

    const finishDraw = (e) => {
        const activeProps = propsContext.props.filter(p => p.active)
        
        if (activeProps.length == 1) {
            shapeCreateSubmit(e, activeProps[0].ref, activeProps[0].type)
        } else if (activeProps.length > 1) {
            let foundProp = null

            activeProps.map(prop => {
                if (prop.border && isPolygonWithinPolygon(e.layer.toGeoJSON(), prop.border)) {
                    foundProp = prop
                    shapeCreateSubmit(e, foundProp.ref, foundProp.type)
                }
            })
            if (!foundProp) {
                setSelectPropModal({props: activeProps, e: e})
            }
        } else {
            setErrorModal(true)
        }
    }

    const isPolygonWithinPolygon = (inner, outer) => {
        var isContained = true;

        outer.map(polygon => {
            if(inner.geometry.type == "Point") {
                if (!pointInPolygon(inner.geometry.coordinates, polygon[0])) {
                    isContained = false;
                }
            } else {
                inner.geometry.coordinates.map(shape => {
                    shape.map(point => {
                        if (!pointInPolygon(point, polygon[0])) {
                            isContained = false;
                        }
                    })
                })
            }
        })

        return isContained
    }

    const editButton = (text, icon, action, className) => {
        return <div role='button' onClick={action} className={`d-flex flex-column align-items-center justify-content-center rounded bg-white hover-bg-primary-core ${className ? className : ''}`} style={{width: '60px', height: '60px'}}>
            <img src={`${Window.static_path}img/${icon}`} className='mb-1' height="20px" width="20px" />
            {text}
        </div>
    }

    const checkMapLayerActive = (name) => {
        let found = false
        
        leaflet.current.state?.mapLayers.eachLayer(l => {
            if(l.name == name) {
                found = true
            }
        })

        return found
    }

    const layerButton = (text, img, action, active, className) => {
        return <div role='button' onClick={action} className={`d-flex flex-column align-items-center mb-1 me-2 ${className ? className : ''}`} style={{maxWidth: '60px'}}>
            <img src={`${Window.static_path}img/${img}`} className={`rounded ${active ? 'border border-4 border-tertiary-main' : ''}`} height='50px' width='50px' />
            <span className='text-break text-center' style={{fontSize: '8px'}}>{text}</span>
        </div>
    }

    const sectionData = () => {
        return Number(sectionPopup.cut_year) > 2005 ? <div className='row align-items-center p-2'>
            <div className='col-6 p-2'>Avdelnings nr</div>
            <div className='col-6 p-2'>{sectionPopup.localID}</div>

            <div className='col-6 p-2'>Huggningsklass</div>
            <div className='col-6 p-2'>Ingen åtgärd</div>

            <div className='col-6 p-2'>Area</div>
            <div className='col-6 p-2'>{sectionPopup.area} ha</div>

            <div className='col-6 p-2'>Ålder</div>
            <div className='col-6 p-2'>{sectionPopup.age} år</div>
        </div> : <div className='row align-items-center p-2'>
            <div className='col-6 p-2'>Avdelnings nr</div>
            <div className='col-6 p-2'>{sectionPopup.localID}</div>

            <div className='col-6 p-2'>Huggningsklass</div>
            <div className='col-6 p-2'>{sectionPopup.felling_class}</div>

            <div className='col-6 p-2'>Area</div>
            <div className='col-6 p-2'>{sectionPopup.area} ha</div>

            <div className='col-6 p-2'>Totalvolym</div>
            <div className='col-6 p-2'>{sectionPopup.volume} m³sk</div>

            <div className='col-6 p-2'>Tall</div>
            <div className='col-6 p-2'>{Math.round(sectionPopup.tall * sectionPopup.volume / 100)} m³sk</div>

            <div className='col-6 p-2'>Gran</div>
            <div className='col-6 p-2'>{Math.round(sectionPopup.gran * sectionPopup.volume / 100)} m³sk</div>
            
            <div className='col-6 p-2'>Löv</div>
            <div className='col-6 p-2'>{Math.round(sectionPopup.lov * sectionPopup.volume / 100)} m³sk</div>

            <div className='col-6 p-2'>TGL %</div>
            <div className='col-6 p-2'>{sectionPopup.tall} | {sectionPopup.gran} | {sectionPopup.lov}</div>

            <div className='col-6 p-2'>Estimerad bonitet</div>
            <div className='col-6 p-2'>{Math.round(sectionPopup.growth_rate)} m³sk/ha/år</div>

            <div className='col-6 p-2'>Biomassa</div>
            <div className='col-6 p-2'>{sectionPopup.bio} ton torrsubstans/ha</div>

            <div className='col-6 p-2'>Grundyta</div>
            <div className='col-6 p-2'>{sectionPopup.grun} m²/ha</div>

            
            <div className='col-6 p-2'>Estimerad ålder</div>
            <div className='col-6 p-2'>{!sectionPopup.age ? 'Okänd ålder' : sectionPopup.age > 100 ? '100+' : `${Math.floor(sectionPopup.age / 10) != 0 ? Math.floor(sectionPopup.age / 10) : ''}0-${Math.floor(sectionPopup.age / 10)+1}0`} år</div>

            <div className='col-6 p-2'><span className="text-nowrap">Estimerad SI</span> (ståndortsindex)</div>
            <div className='col-6 p-2'>{sectionPopup.major_tree}{sectionPopup.si}</div>

            <div className='col-6 p-2'>Medelhöjd</div>
            <div className='col-6 p-2'>{sectionPopup.height} m</div>

            <div className='col-6 p-2'>Medeldiameter</div>
            <div className='col-6 p-2'>{sectionPopup.diameter} cm</div>


            <div className='col-6 p-2'>Skannat år</div>
            <div className='col-6 p-2'>{sectionPopup.scanned_year}</div>

            <div className='col-6 p-2'>Framräknat till</div>
            <div className='col-6 p-2'>{new Date().getFullYear()}</div>
        </div>
    }

    const sectionEditData = () => {
        return <div className="row align-items-center p-2">
            <div className="col-6 p-2">Avdelnings nr</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.localID}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, localID: val}})}/>
            </div>

            <div className="col-6 p-2">Huggningsklass</div>
            <div className="col-6 p-2">
                <VBDropdown
                    initial={sectionPopup?.felling_class}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    options={FellingClasses}
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, felling_class: val.value}})} />
            </div>

            <div className="col-6 p-2">Totalvolym (m³sk)</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.volume}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, volume: val}})} />
            </div>

            <div className="col-6 p-2">Tall|Gran|Löv</div>
            <div className="col-6 p-2">TGL %</div>

            <div className="col-6 p-2">Tall %</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.tall}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    error={sectionPopup?.errors?.tgl}
                    onChange={(val) => updateTGL(parseInt(val), 'tall')} />
            </div>

            <div className="col-6 p-2">Gran %</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.gran}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    error={sectionPopup?.errors?.tgl}
                    onChange={(val) => updateTGL(parseInt(val), 'gran')} />
            </div>

            <div className="col-6 p-2">Löv %</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.lov}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    error={sectionPopup?.errors?.tgl}
                    onChange={(val) => updateTGL(parseInt(val), 'lov')} />
            </div>

            <div className="col-6 p-2">Ålder (år)</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.age}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, age: val}})} />
            </div>

            <div className="col-6 p-2">SI</div>
            <div className="col-6 p-2">
                <VBDropdown
                    value={sectionPopup?.si}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    options={SiValues.map(i => {
                        return {label: i, value: i}
                    })}
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, si: val.value}})} />
            </div>

            <div className="col-6 p-2">Snitt höjd (m)</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.height}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, height: val}})} />
            </div>

            <div className="col-6 p-2">Snitt diameter (cm)</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.diameter}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, diameter: val}})} />
            </div>

            <div className="col-6 p-2">Grundyta (m³/ha)</div>
            <div className="col-6 p-2">
                <VBInput
                    initial={sectionPopup.grun}
                    inputStyle={{ minHeight: 0, paddingTop: "5px", paddingBottom: "5px" }}
                    type="number"
                    onChange={(val) => setSectionPopup({...sectionPopup, edit: {...sectionPopup.edit, grun: val}})} />
            </div>

            <div className="col-12">
                <button className='n-btn btn-forest-green rounded btn-sm fw-medium w-100 my-2' onClick={() => updateSectionData()}>Spara</button>
                <button className='n-btn btn-forest-green btn-outline rounded btn-sm fw-medium w-100 mb-2' onClick={() => setSectionPopup({...sectionPopup, enableEdit: false})}>Avbryt</button>
            </div>
        </div>
    }

    return <RootContainer className='position-relative overflow-hidden' viewportHeight={viewportHeight}>
        <VBLoadingScreen zIndex={1035} show_loading={loading} className='position-absolute' />

        <VBLeaflet ref={leaflet} id='map_karta' baseMap='Mapbox Satelite' finishDraw={(e) => finishDraw(e)} />
        
        <PropselectorContainer className='position-absolute z-1040'>
            <PropSelector />
        </PropselectorContainer>

        <ToolboxContainer className='position-absolute z-1010'>
            <div className='d-flex flex-column bg-neutral-cloud shadow rounded p-1'>
                <div role='button' onClick={() => leaflet.current.zoomIn()} className='d-flex justify-content-center align-items-center bg-white hover-bg-supp-forest-green-300 rounded-1 mb-1' style={{height: '30px'}}>
                    <img src={`${Window.static_path}img/icons/plus.svg`} height='20px' width='20px' />
                </div>
                <div role='button' onClick={() => leaflet.current.zoomOut()} className={`d-flex justify-content-center align-items-center bg-white hover-bg-supp-forest-green-300 rounded-1 ${propsContext.props.filter(p => p.active == true).length > 0 ? 'mb-3' : ''}`} style={{height: '30px'}}>
                    <img src={`${Window.static_path}img/icons/minus.svg`} height='30px' width='30px' />
                </div>

                {propsContext.props.filter(p => p.active == true).length > 0 && <>
                    <div role='button' onClick={() => {setMapTools(mapTools == 'layers' ? false : 'layers'); leaflet.current.setMode('view'); doRefresh(true)}} className={`d-flex justify-content-center align-items-center ${mapTools == 'layers' ? 'bg-supp-forest-green' : 'bg-white hover-bg-supp-forest-green-300'} rounded-1 mb-1`} style={{height: '30px'}}>
                        <img src={`${Window.static_path}img/icons/map-layers.svg`} className={mapTools == 'layers' ? 'white-filter' : ''} height='20px' width='20px' />
                    </div>
                    <div role='button' onClick={() => {setMapTools(mapTools == 'tools' ? false : 'tools'); leaflet.current.setMode('view'); doRefresh(true)}} className={`d-flex justify-content-center align-items-center ${mapTools == 'tools' ? 'bg-supp-forest-green' : 'bg-white hover-bg-supp-forest-green-300'} rounded-1 mb-1`} style={{height: '30px'}}>
                        <img src={`${Window.static_path}img/icons/map-edit.svg`} className={mapTools == 'tools' ? 'white-filter' : ''} height='20px' width='20px' />
                    </div>
                    <div role='button' onClick={() => setShowLegend(!showLegend)} className={`d-flex justify-content-center align-items-center ${showLegend ? 'bg-supp-forest-green' : 'bg-white hover-bg-supp-forest-green-300'} rounded-1`} style={{height: '30px'}}>
                        <img src={`${Window.static_path}img/icons/info_filled.svg`} className={showLegend ? 'white-filter' : ''} height='20px' width='20px' />
                    </div>
                </>}
                {/*<div role='button' className='d-flex justify-content-center align-items-center bg-white hover-bg-supp-forest-green-300 rounded-1' style={{height: '30px'}}>
                    <img src={`${Window.static_path}img/icons/question_filled.svg`} height='20px' width='20px' />
                </div>*/}
            </div>
        </ToolboxContainer>

        <div className={`${showLegend ? 'd-flex' : 'd-none'} flex-column bg-neutral-cloud shadow rounded z-1010 position-absolute p-1 mt-3`} style={{width: '250px', right: '10px', bottom: '120px'}}>
            <div className='bg-white caption p-2'>
                Huggningsklasser
                {legend.sort((a, b) => fellingClassesOrder.indexOf(a) == -1 ? 1 : fellingClassesOrder.indexOf(b) == -1 ? -1 : fellingClassesOrder.indexOf(a) - fellingClassesOrder.indexOf(b)).map(i => {
                    return <div className='d-flex mb-1'>
                        <div className='me-1' style={{backgroundColor: FellingClassesLegend[i] ? FellingClassesLegend[i].color : '#bb0000', height: '16px', minWidth: '16px'}}></div><b>{i}</b>: {FellingClassesLegend[i] ? FellingClassesLegend[i].text : 'Okänd'}
                    </div>
                })}
            </div>
        </div>

        <MapTools className={`position-fixed z-1010 ${mapTools || leaflet.current?.state?.mode != 'view' || (!markerModal.show && markerModal.selected) || leaflet.current?.state.control ? 'show' : ''}`}>
            <div className='d-flex flex-column bg-neutral-cloud shadow rounded-top caption px-2 pt-2'>
                {leaflet.current?.state.control && <>
                    <div className='text-center fw-bold mb-1'>Du ritar nu</div>
                    
                    <div className='d-flex pb-2'>
                        {editButton('Spara', 'icons/save.svg', () => {leaflet.current.completeDraw(); doRefresh(true)}, 'me-2')}

                        {editButton('Avbryt', 'icons/ui-close.svg', () => {leaflet.current.stopDraw(); doRefresh(true)})}
                    </div>
                </>}

                {(leaflet.current?.state?.mode == 'edit' || leaflet.current?.state?.mode == 'delete') && <>
                    <div className='text-center fw-bold mb-1'>{leaflet.current?.state?.mode == 'edit' ? 'Du redigerar nu' : 'Du tar nu bort'}</div>
                    
                    <div className='d-flex pb-2'>
                        {editButton('Spara', 'icons/save.svg', () => {leaflet.current.state.mode == 'edit' ? handleShapeEdit() : handleShapeDelete(); leaflet.current.save(); doRefresh(true)}, 'me-2')}

                        {editButton('Avbryt', 'icons/ui-close.svg', () => {leaflet.current.revert(); setMapData(false); doRefresh(true)})}
                    </div>
                </>}

                {!markerModal.show && markerModal.selected && <>
                    <div className='text-center fw-bold mb-1'>Placera markör</div>
                    
                    <div className='d-flex justify-content-center pb-2'>
                        {editButton('Avbryt', 'icons/ui-close.svg', () => {leaflet.current.stopDraw(); setMarkerModal({show: false})})}
                    </div>
                </>}

                {mapTools == 'tools' && <>
                    <div className='text-center fw-bold mb-1'>Verktyg:</div>

                    <div className='d-flex pb-2'>
                        {editButton('Rita', 'icons/ui-edit.svg', () => {setMapTools(false); leaflet.current.startDraw('Polygon'); doRefresh(true)}, 'me-2')}

                        {editButton('Redigera', 'icons/pencil-square.svg', () => {setMapTools(false); leaflet.current.setMode('edit'); doRefresh(true)}, 'me-2')}

                        {editButton('Radera', 'icons/delete.svg', () => {setMapTools(false); leaflet.current.setMode('delete'); doRefresh(true)}, 'me-2')}

                        {editButton('Min plats', 'icons/my-location.svg', () => {setMapTools(false); setUserLocation(leaflet.current.state.map)}, 'me-2')}

                        {editButton('Markör', 'icons/marker.svg', () => {setMapTools(false); setMarkerModal({show: true, selected: null})})}
                    </div>
                </>}

                {mapTools == 'layers' && <div className='bg-white p-2'>
                    <div className='mb-2'>Välj bakgrundskarta:</div>

                    <div className='d-flex'>
                        {layerButton('Satellit', 'illustration/Mapbox-satelite.png', () => {leaflet.current.setBaseMap('Mapbox Satelite'); doRefresh(true)}, leaflet.current.state.baseMap.name == 'Mapbox Satelite', 'me-2')}

                        {layerButton('Mapbox Plain', 'illustration/Mapbox-plain.png', () => {leaflet.current.setBaseMap('Mapbox Plain'); doRefresh(true)}, leaflet.current.state.baseMap.name == 'Mapbox Plain', 'me-2')}

                        {layerButton('Open Street Map', 'illustration/Open-Street-Map.png', () => {leaflet.current.setBaseMap('Open Street Map'); doRefresh(true)}, leaflet.current.state.baseMap.name == 'Open Street Map')}
                    </div>
                    

                    <div className='mb-2'>Växla lager:</div>

                    <div className='d-flex'>
                        {layerButton('Avverkningsanmälan', 'illustration/Avverkningsanmalan.png', () => {leaflet.current.toggleMapLayer('Avverkningsanmälan'); doRefresh(true)}, checkMapLayerActive('Avverkningsanmälan'), 'me-2')}

                        {layerButton('Utförd avverkning', 'illustration/Utford-avverkning.png', () => {leaflet.current.toggleMapLayer('Utförd avverkning'); doRefresh(true)}, checkMapLayerActive('Utförd avverkning'), 'me-2')}

                        {layerButton('Nyckelbiotoper', 'illustration/Nyckelbiotoper.png', () => {leaflet.current.toggleMapLayer('Nyckelbiotoper'); doRefresh(true)}, checkMapLayerActive('Nyckelbiotoper'), 'me-2')}

                        {layerButton('Fornlämningar', 'illustration/Fornlamningar.png', () => {leaflet.current.toggleMapLayer('Fornlämningar'); doRefresh(true)}, checkMapLayerActive('Fornlämningar'), 'me-2')}

                        {layerButton('Vattenskyddsområden', 'illustration/Vattenskyddsomraden.png', () => {leaflet.current.toggleMapLayer('Vattenskyddsområden'); doRefresh(true)}, checkMapLayerActive('Vattenskyddsområden'), 'me-2')}

                        {layerButton('Object med naturvärde', 'illustration/Object-med-naturvarde.png', () => {leaflet.current.toggleMapLayer('Object med naturvärde'); doRefresh(true)}, checkMapLayerActive('Object med naturvärde'))}
                    </div>

                    <div className='d-flex'>
                        {layerButton('Sumpskog', 'illustration/Sumpskog.png', () => {leaflet.current.toggleMapLayer('Sumpskog'); doRefresh(true)}, checkMapLayerActive('Sumpskog'))}

                        {layerButton('Observationer invasiva arter', 'illustration/Observationer-invasiva-arter.png', () => {leaflet.current.toggleMapLayer('Observationer invasiva arter'); doRefresh(true)}, checkMapLayerActive('Observationer invasiva arter'))}

                        {layerButton('Observationer rödlistade arter', 'illustration/Observationer-rodlistade-arter.png', () => {leaflet.current.toggleMapLayer('Observationer rödlistade arter'); doRefresh(true)}, checkMapLayerActive('Observationer rödlistade arter'))}

                        {layerButton('Avdelnings nr', 'illustration/Avdelnings-nr.png', () => {setShowSectNr(!showSectNr)}, showSectNr)}
                    </div>
                </div>}
            </div>
        </MapTools>

        <SectionPopup className='bg-neutral-cloud-100 py-2' show={sectionPopup} onHide={() => setSectionPopup(false)} placement='start' backdrop={false} enforceFocus={false}>
            <div className="d-flex justify-content-between align-items-center subtitle bg-neutral-cloud hover-bg-neutral-cloud-700 px-4 py-2 my-3 " role="button" onClick={() => setSectionPopup(false)}>
                <span className="fw-medium">Fastighet</span>
                <span className="supp-forest-green ms-2">{sectionPopup.designation}</span>
                <img src={`${Window.static_path}img/icons/caret.svg`} className="supp-forest-green-filter rotate-50" width="30" height="30" />
            </div>

            <CustomScrollbar className="ms-4 me-2 pe-2">
                {sectionPopup && <>
                <div className="neutral-charcoal bg-neutral-cloud subtitle rounded my-3 p-3">
                    <div className="d-flex p-2">
                        <div className="col-12 fw-medium" id="sectionDataContainer">
                            Översiktlig data
                            <img src={`${Window.static_path}img/icons/info-circle.svg`} role="button" className="float-end charcoal-filter" width="20px" height="20px" onClick={() => setInfoModal({title: 'Information', message: <>
                                <p className='neutral-charcoal'>Vi hämtar data från flera olika källor vissa helt öppna och andra under olika licenser, några av våra källor är: Lantmäteriet, Skogsstyrelsen, Naturvårdsverket, Riksantikvarieämbetet och Sveriges lantbruksuniversitet.</p>
                                <p className='neutral-charcoal'>Vi tackar alla som levererar data till oss enormt mycket för deras hårda jobb med att tillhandahålla den data vi jobbar med.</p>
                            </>})} />
                            {sectionPopup.enableEdit ? <img src={`${Window.static_path}img/icons/cross.svg`} role="button" className="float-end charcoal-filter me-2" width="20px" height="20px" onClick={() => setSectionPopup({...sectionPopup, enableEdit: false})} /> : <img src={`${Window.static_path}img/icons/ui-edit.svg`} role="button" className="float-end charcoal-filter me-2" width="20px" height="20px" onClick={() => setSectionPopup({...sectionPopup, enableEdit: true})} />}
                        </div>
                    </div>

                    {sectionPopup.enableEdit ? sectionEditData() : sectionData()}
                </div>

                <div>
                    <img src={`${Window.static_path}img/icons/info-circle.svg`} role="button" className="float-end forest-green-filter" heigth="20px" width="20px" onClick={() => setInfoModal({title: 'Sälj virke på Treebula', message: 'Vill du säkerställa rätt virkespris? Gör som tusentals andra skogsägare och använd Treebulas marknadsplats för att sälja virke.  Du lägger upp den avdelning/ar du vill ta in anbud på och får anbud av alla potentiella köpare på så sätt säkerställer du rätt virkespris. Tjänsten är helt kostnadsfri för dig som skogsägare och du har helt fri prövningsrätt det vill säga är du inte nöjd med anbuden kan du tacka nej.'})} />
                    <button className='n-btn btn-forest-green btn-sm w-100 my-2' onClick={() => createAdFromSection()}>Ta in anbud på denna avdelning</button>
                </div>

                <div className='bg-neutral-cloud text-center rounded p-3 my-3'>
                    <h3 className="n-fs-5 neutral-charcoal fw-medium">Förväntat netto intäkt/kostnad</h3>
                    <h2 className="n-h4 supp-forest-green">{(sectionPopup?.actions.length > 0 ? sectionPopup.actions.reduce((acum, itm) => { return acum + itm.result }, 0) : 0).toLocaleString().replaceAll(',', ' ')} kr</h2>
                </div>

                <div className='neutral-charcoal bg-neutral-cloud subtitle rounded my-3 p-3'>
                    <p className="fw-medium">Risker</p>
                    
                    <div className='d-flex align-items-center mb-2'>
                        <div className='col-1'>
                            <img src={Window.static_path + "img/map-markers/marker_fire_green.png"} width="26px" height="26px" />
                        </div>
                        <div className='col-6'>
                            <span>Brand Risk</span>
                        </div>
                        <div className='col-5'>
                            <span className='fw-bold'>{sectionPopup?.risks?.beetle}</span>
                        </div>
                    </div>
                    
                    <div className='d-flex align-items-center'>
                        <div className='col-1'>
                            <img src={Window.static_path + "img/map-markers/marker_bug_green.png"} width="26px" height="26px" />
                        </div>
                        <div className='col-6'>
                            <span>Granbarkborre Risk</span>
                        </div>
                        <div className='col-5'>
                            <span className='fw-bold'>{sectionPopup?.risks?.fire}</span>
                        </div>
                    </div>
                </div>

                <div className='bg-neutral-cloud rounded p-3 my-3'>
                    <div className='d-flex flex-column p-2'>
                        <p className="col-12 neutral-charcoal fw-medium mb-2">Lägg till anteckning</p>

                        <div className="col-12">
                            {!refresh && <VBTextarea resize="none" height="150px" initial={sectionPopup.note} onChange={(val) => setSectionPopup({...sectionPopup, note: val})} />}
                        </div>

                        <div className="col-12">
                            <button className='n-btn btn-forest-green btn-sm w-100 mt-2' onClick={() => saveNote()}>Spara anteckning</button>
                        </div>
                    </div>
                </div>

                <div className='bg-neutral-cloud rounded p-3 my-3'>
                    <div className='d-flex flex-column subtitle p-2'>
                        <div className="col-12 neutral-charcoal fw-medium mb-2">
                            Verktygslåda
                        </div>

                        <div className='col-12'>
                            <button className='n-btn btn-forest-green btn-sm w-100 mt-2' onClick={() => {
                                leaflet.current.setState({mode: 'edit'}, () => {
                                    sectionPopup.layer.fire('click')
                                    setSectionPopup(false)
                                })
                            }}>Redigera lager</button>
                        </div>

                        <div className='col-12'>
                            <button className='n-btn btn-forest-green btn-sm w-100 mt-2' onClick={() => {
                                leaflet.current.setState({mode: 'delete'}, () => {
                                    sectionPopup.layer.fire('click')
                                    setSectionPopup(false)
                                })
                            }}>Radera lager</button>
                        </div>
                    </div>
                </div>
                </>}
            </CustomScrollbar>
        </SectionPopup>

        {/* INFO Modal */}
        <Modal show={infoModal} onHide={() => setInfoModal(false)} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
            {infoModal.title && <Modal.Header className="align-items-start border-0" closeButton>
                <h2 className="neutral-charcoal mb-0 n-h4">{infoModal.title}</h2>
            </Modal.Header>}
            {infoModal.message && <Modal.Body className="py-0">
                <p className='neutral-charcoal'>{infoModal.message}</p>
            </Modal.Body>}
            <Modal.Footer className="justify-content-end border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => setInfoModal(false)}>Stäng</button>
            </Modal.Footer>
        </Modal>

        {/* MARKER PLACEMENT POPUP */}
        <Modal show={markerModal.show} onHide={() => setMarkerModal({show: false})} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h2 className="neutral-charcoal mb-0 n-h4">{markerModal.id ? 'Redigera' : 'Skapa'} Markör</h2>
            </Modal.Header>
            <Modal.Body className="neutral-charcoal py-0">
                <p>Markörer hjälper dig hålla kolla på viktiga punkter i din skog.</p>
                <p className="mb-1">Välj ikon:</p>

                {markerModal.error && <p className="text-danger">{markerModal.error}</p>}

                {mapMarkers.map(m => <div role='button' onClick={() => setMarkerModal({...markerModal, selected: m})} className={`m-2 d-inline-flex flex-justify-center flex-align-center justify-content-center rounded ${markerModal.selected == m ? 'bg-neutral-cloud-700' : 'hover-bg-neutral-cloud-700'}`} style={{ width: "64px", height: "72px", transition: ".3s" }}>
                        <img src={m} className="p-1" width="48px" />
                    </div>
                )}

                <p className="mb-1 mt-2">Namn</p>
                <VBInput
                    initial={markerModal?.name}
                    onChange={(val) => markerModal.name = val} />

                <p className="mb-1 mt-2">Beskrivning</p>
                <VBTextarea
                    initial={markerModal?.comment}
                    onChange={(val) => markerModal.comment = val} 
                    resize="none" 
                    height="200px" />
            </Modal.Body>
            <Modal.Footer className="justify-content-between border-0">
                <button className='n-btn btn-forest-green rounded btn-sm w-100 my-2' onClick={() => {
                    if(!markerModal.selected) {
                        setMarkerModal({...markerModal, error: '*Vänligen välj en markör innan du fortsätter.'})
                    } else if(markerModal.id) {
                        updateMarker()
                    } else {
                        leaflet.current.startDraw('Marker', {
                            icon: L.icon({
                                iconUrl: markerModal.selected,
                                iconSize: [38, 95],
                                iconAnchor: [18, 74]
                            })
                        })
                        setMarkerModal({...markerModal, show: false})
                    }
                }}>
                    {markerModal.id ? 'Spara' : 'Välj plats på kartan'}
                </button>

                {markerModal.id && <button className='n-btn btn-forest-green rounded btn-outline btn-sm w-100 my-2' onClick={() => {setDeleteMarkerModal({id: markerModal.id, ref: markerModal.ref, type: markerModal.type}); setMarkerModal({show: false})}}>Radera</button>}
            </Modal.Footer>
        </Modal>
        
        {/* CONFIRM MARKER DELETE Modal */}
        <Modal show={deleteMarkerModal} onHide={() => setDeleteMarkerModal(false)} size="md" contentClassName="bg-neutral-cloud p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h3 className="neutral-charcoal mb-0 n-h4">Är du säker på att du vill radera?</h3>
            </Modal.Header>
            <Modal.Footer className="justify-content-between border-0">
                <button type="button" className="n-btn btn-forest-green btn-sm" onClick={() => setDeleteMarkerModal(false)}>Nej</button>
                <button type="button" className="n-btn btn-forest-green btn-sm" onClick={() => {
                    setLoading(true)
                    deleteMarker(deleteMarkerModal.id, deleteMarkerModal.ref, deleteMarkerModal.type).then(() => {
                        setDeleteMarkerModal(false)
                        setMapData(false)
                        setLoading(false)
                    })
                }}>Radera</button>
            </Modal.Footer>
        </Modal>
        
        {/* CONFIRM PROP Modal */}
        <Modal show={selectPropModal} onHide={() => {leaflet.current.state.map.removeLayer(selectPropModal.e.layer); setSelectPropModal(false)}} size="lg" contentClassName="bg-neutral-cloud p-2" centered>
            <Modal.Header className="align-items-start border-0" closeButton>
                <h2 className="neutral-charcoal mb-0 n-h4">Vilken fastighet redigerar du?</h2>
            </Modal.Header>
            <Modal.Body className="py-0">
                <p className='neutral-charcoal'>Eftersom du har flera fastigheter synligt behöver vi veta vilken fastighet du redigerar</p>

                {selectPropModal && <VBDropdown
                    options={selectPropModal.props.map(p => {return {label: p.designation, value: p.ref, type: p.type}})}
                    onChange={(val) => setSelectPropModal({...selectPropModal, selected: val})}
                    error={selectPropModal?.error}
                    onFocus={() => setSelectPropModal({...selectPropModal, error: ''})} />}
            </Modal.Body>
            <Modal.Footer className="justify-content-end border-0">
                <button type="button" className="n-btn btn-forest-green" onClick={() => {
                    if(!("selected" in selectPropModal)) {
                        setSelectPropModal({...selectPropModal, error: 'Du måste välja en fastighet för att fortsätta'})
                    } else {
                        let prop = selectPropModal.props.find(p => p.ref == selectPropModal.selected.value)
                        shapeCreateSubmit(selectPropModal.e, prop.ref, prop.type)
                        setSelectPropModal(false)
                    }
                }}>Fortsätt</button>
            </Modal.Footer>
        </Modal>

        {/* ERROR Modal */}
        <ErrorModal show={errorModal} title={errorModal?.title} message={errorModal?.message} onToggle={() => setErrorModal(false)} />
    </RootContainer>
}