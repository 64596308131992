import React from 'react';
import styled from 'styled-components';
import { getUserRights } from "../../api";
import Offcanvas from 'react-bootstrap/Offcanvas';

const Link = styled.a`
    &:hover {
        text-decoration: underline;
    }
`

class Navigation extends React.Component {
    constructor() {
        super();

        this.state = {
            isLogged: Window.user_data.isLogged,
            isAdmin: Window.user_data.isAdmin,
            userRoles: Window.user_data.roles,
            showMobileMenu: false
        }
    }

    updateNavbar() {
        getUserRights().then(resp => {
            if (resp.ok) {
                resp.json().then((json) => {
                    this.setState({ isLogged: json.is_login, userRoles: json.roles, isAdmin: json.is_admin })
                })
            } else {
                alert('Oops something went wrong')
            }
        })
    }

    // When the component is mounted, observe the body for class changes to update the navbar
    componentDidMount() {
        // Use the line below to update the navbar in other components:
        // document.getElementsByTagName('body')[0].classList.toggle("nav-update")

        this.observer = new MutationObserver(record => {
            record.forEach((event) => {
                if(event.attributeName == 'class') {
                    this.updateNavbar(); // Trigger update when class changes
                }
            })
        })

        this.observer.observe(document.getElementsByTagName('body')[0], { attributes: true })
    }

    componentWillUnmount() {
        if(this.observer) {
            this.observer.disconnect();
        }
    }

    // Toggle the active dropdown menu
    setActiveMenu(name) {
        if(this.state.dropdown == name) {
            name = false
        }
        this.setState({ dropdown: name })
    }

    render() {
        const navbarButtons = {}

        if (this.state.isLogged) {
            navbarButtons.first = { href: '/logout/', text: 'Logga ut' } 
        } else {
            navbarButtons.first = { href: '/logga-in/', text: 'Bli medlem' }
        }

        if (this.state.isLogged) {
            if (this.state.isAdmin) {
                navbarButtons.second = { href: '/vb-admin/', text: 'Admin', class: 'btn-charcoal' }
            } else {
                if (this.state.userRoles.indexOf("seller") != -1) {
                    navbarButtons.second = { href: '/skogsbruksplan/karta/', text: 'Mina sidor', class: 'btn-charcoal' }
                } else {
                    navbarButtons.second = { href: '/my-account/', text: 'Mina sidor', class: 'btn-charcoal' }
                }
            }
        } else {
            navbarButtons.second = { href: '/logga-in/', text: 'Logga in', class: 'btn-core' }
        }

        return <>
            {/* Overlay for the dropdown, hidden unless a dropdown is active */}
            {this.state.dropdown && <div className="d-none d-xl-block position-fixed top-0 start-0 h-100 w-100 z-1070 bg-transparent overflow-hidden" onClick={() => {this.setState({ dropdown: false })}}>
                <div className="position-relative"></div>
            </div>}

            {/* Desktop Navbar */}
            <div className="container-fluid position-sticky bg-white top-0 g-0 z-1090" style={{borderBottom: "1px solid rgba(5,5,5,0.06)"}}>
                {/* <p className="d-none d-xl-block bg-primary-core text-center n-fs-6 py-3 mb-0">Virkesbörsen heter nu Treebula – som kund påverkas du inte av namnbytet.</p> */} 

                <div className="container">
                    <div className="row d-none d-xl-flex align-items-center py-4">
                        <div className="col">
                            <a href="/">
                                <img src={`${Window.static_path}img/icons/treebula_lockup_charcoal.svg`} style={{ width: "180px" }} />
                            </a>
                        </div>

                        <div className="col text-nowrap text-end neutral-charcoal">
                            {Window.navbar_data.map(i => {
                                if(i.url != 'dropdown') {
                                    return <a href={(this.state.isLogged && i.urlIfLogged) ? i.urlIfLogged : i.url} className='n-btn btn-sm btn-charcoal hover-neutral-charcoal btn-ghost text-nowrap px-3 me-2'>{i.label}</a>
                                } else {
                                    return <button className={`n-btn btn-sm btn-charcoal btn-ghost neutral-charcoal text-nowrap ${this.state.dropdown == i.name ? 'bg-neutral-charcoal-200' : ''} me-2 pe-3`} onClick={() => this.setActiveMenu(i.name)}>{i.label}<img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} height="20px" width="20px" alt='dropdown arrow' className={`d-flex-inline neutral-pebble-filter ${this.state.dropdown == i.name ? 'rotate-50 pe-1' : 'ps-1'} `} style={{ transition: 'transform .15s ease' }} /></button>
                                }
                            })}

                            {/* Login/logout buttons */}
                            <a href={navbarButtons.first.href} className="me-2 n-btn btn-sm btn-outline btn-charcoal">{navbarButtons.first.text}</a>
                            <a href={navbarButtons.second.href} className={`n-btn btn-sm ${navbarButtons.second.class}`}>{navbarButtons.second.text}</a>
                        </div>
                    </div>
                </div>
                {/* for SEO purposes, displaying the links in html */}
                {Window.navbar_data.map((i) => {
                    if (i.url === 'dropdown' && i.content) {
                        return <div key={i.name} className={`container-fluid position-absolute p-0 d-none ${this.state.dropdown === i.name ? "d-xl-block" : ""}`}>
                            <div className="position-relative bg-white">
                                <div className="container">
                                    <div className="row bg-white">
                                        <div className="col-5 ps-3 ms-3 pe-3 py-2">
                                            <h4>{i.content.title}</h4>
                                            <p className="n-fs-7">{i.content.description}</p>
                                        </div>
            
                                        {Array.from({ length: i.columns }).map((_, num) => (
                                            <div key={num} className="col-3 mb-4">
                                                {i.content.items.map(link => {
                                                    if (link.column === num + 1 || (!link.column && num === 0)) {
                                                        return <>
                                                            <Link key={link.label} href={(this.state.isLogged && link.urlIfLogged) ? link.urlIfLogged : link.url} className="d-block fw-normal n-fs-6 mt-3">
                                                                {link.label}
                                                            </Link>
                                                            {link.subItems && link.subItems.map(sub => (
                                                                <Link key={sub.label} href={(this.state.isLogged && sub.urlIfLogged) ? sub.urlIfLogged : sub.url} className="d-block n-fs-7 mt-1 ms-1">
                                                                    {sub.label}
                                                                </Link>
                                                            ))}
                                                        </>
                                                    }
                                                })}
                                            </div>
                                        ))}
                                    </div>
                                </div>
            
                                {/* Footer section inside the dropdown */}
                                <div className="container-fluid bg-neutral-charcoal">
                                    <div className="container">
                                        <div className="d-flex bg-neutral-charcoal py-2">
                                            <a href="/login/" className='primary-core n-fs-7 ps-3'>Skapa din annons idag! Bli medlem</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                })}
            </div>
            
            <div className="position-sticky top-0 z-1020">
                {/* <p className="d-xl-none bg-primary-core text-center n-fs-6 py-3 mb-0">Virkesbörsen heter nu Treebula – som kund påverkas du inte av namnbytet.</p> */}
                <div className="d-flex d-xl-none justify-content-between align-items-center bg-white p-3">
                    <div className="d-flex">
                        <a href="/"><img src={`${Window.static_path}img/icons/treebula_lockup_charcoal.svg`} style={{ maxHeight: "50px", maxWidth: "150px" }} /></a>
                    </div>
                    <div className="d-flex text-end">
                        <div className="d-none d-sm-inline-block" style={{marginTop: "2px"}}>
                            <a href={navbarButtons.second.href} className={`n-btn btn-xs ${navbarButtons.second.class} me-3`}>{navbarButtons.second.text}</a>
                        </div>

                        <div className="d-inline-block px-1 py-2" type="button" onClick={() => { this.setState({ showMobileMenu: true }) }}>
                            <div className="rounded bg-neutral-charcoal" style={{height: "2px", width: "24px", marginBottom: "10px"}}></div>
                            <div className="rounded bg-neutral-charcoal" style={{height: "2px", width: "24px"}}></div>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas className="container h-100" show={this.state.showMobileMenu} placement='top'>
                <div className="d-flex justify-content-between align-items-center fixed-top px-3" style={{margin: "24px 0"}}>
                    <div className="col">
                        <img src={`${Window.static_path}img/icons/treebula_lockup_charcoal.svg`} width="150px" />
                    </div>
                    <div className="col text-end">
                        <a type="button" onClick={() => { this.setState({ showMobileMenu: false }) }}>
                            <img src={`${Window.static_path}img/icons/nav-cross.svg`} height="32px" width="32px" />
                        </a>
                    </div>
                </div>

                <div className="row position-relative overflow-scroll" style={{maxHeight: `calc(100vh - 210px)`, top: "80px"}}>
                    <div className="col-12 mt-3">
                        {Window.navbar_data.map(i => {
                            if(i.url != 'dropdown') {
                                return <a href={(this.state.isLogged && i.urlIfLogged) ? i.urlIfLogged : i.url} className='d-block neutral-charcoal n-fs-5 mb-3'>{i.label}</a>
                            } else {
                                return <>
                                    <div className='text-nowrap mb-3' onClick={() => this.setActiveMenu(i.name)}>
                                        {i.label}
                                        <img src={`${Window.static_path}img/icons/dropdown-arrow.svg`} height="32px" width="32px" alt='dropdown arrow' className={`d-flex-inline neutral-pebble-filter float-end ${this.state.dropdown == i.name ? 'rotate-50 ps-2' : 'pe-2'} `} style={{ transition: 'transform .15s ease' }} />
                                    </div>

                                    {this.state.dropdown == i.name && <ul className="list-unstyled">
                                        {i.content.items.map(link => {
                                            return <>
                                                <li className="mt-3 ps-2">
                                                    <a href={(this.state.isLogged && link.urlIfLogged) ? link.urlIfLogged : link.url} className="n-fs-6 neutral-charcoal d-block">{link.label}</a>
                                                </li>
                                                {link.subItems && link.subItems.map(sub => {
                                                    return <li className="mt-1 ps-4">
                                                        <a href={(this.state.isLogged && sub.urlIfLogged) ? sub.urlIfLogged : sub.url} className="n-fs-7 neutral-pebble d-block">{sub.label}</a>
                                                    </li>
                                                })}
                                            </>
                                        })}    
                                    </ul>}
                                </>
                            }
                        })}
                    </div>
                </div>

                <div className="row fixed-bottom">
                    <div className="col-12 px-4">
                        <a href={navbarButtons.second.href} className={`n-btn btn-sm ${navbarButtons.second.class} d-block w-100 mb-2`}>{navbarButtons.second.text}</a>
                        <a href={navbarButtons.first.href} className="n-btn btn-sm btn-outline btn-charcoal d-block w-100 mb-2">{navbarButtons.first.text}</a>
                    </div>
                </div>
            </Offcanvas>
        </>
    }
}

export default Navigation;