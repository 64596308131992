import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components';

const BarRoot = styled.div`
    canvas {
        min-height: 220px;
        @media (min-width: 1200px) {
            min-height: 320px;
        }
    }
`

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

function BarChart({ data, x, y }) {
    return <BarRoot>
        <Bar options={{
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true
                },
                datalabels: {
                    color: 'transparent'
                }
            },
            scales: {
                x: x,
                y: y,
            },
            maintainAspectRatio: false,
            responsive: true
        }} data={data} />
    </BarRoot>
}

BarChart.defaultProps = {
    x: {
        ticks: {
            color: '#1C332A',
            fontSize: 10,
            fontWeight: 'bold',
            autoSkip: false,
        },
        grid: {
            drawBorder: false,
            display: false,
        },
    },
    y: {
        ticks: {
            color: '#1C332A',
            fontSize: 60,
            fontWeight: 'bold',
            beginAtZero: true,
            min: 0,
            max: 1000,
            stepSize: 10,
            grace: "0.005%",
            callback: function (value, index, ticks) {
                return value + " " + '-';
            },
        },
        grid: {
            drawBorder: false,
            display: false,
        },
    }
}
export default BarChart