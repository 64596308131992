import React from 'react';
import Cookies from 'js-cookie';

import VBPhoneInput from '@virkesborsen/vb-js-commons/lib/components/new/vbPhoneInput';
import VBMultiOption from '@virkesborsen/vb-js-commons/lib/components/new/vbMultiOption';
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components";
import VBInput from "@virkesborsen/vb-js-commons/lib/components/new/vbInput";


// Set z-index for .jbyKgy dropdown options to ensure visibility in karta and overblick when PropModal is open.
const ComplementModal= styled(Modal)`
   z-index:1056;
`
class ComplementInfo extends React.Component {
    constructor(){
        super();
        this.state={
            errors: "",
            munis: [],
            roles: [],
            phoneno: "",
            email: "",
            complementInfoModal: Window.user_data.showUserComplementModal,
        }
        this.rolesRef = React.createRef();
        this.munisRef = React.createRef();
        this.phoneInputRef = React.createRef();
        this.emailInputRef = React.createRef();
    }

    submit(){
        const headers = {
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        var formData = new FormData();
        formData.append("muni", this.state.munis)
        formData.append("roles", this.state.roles)
        formData.append("mobile_phone_number", this.state.phoneno)
        formData.append("email", this.state.email)
        fetch("/user_complement_info_landing/", {
            method: 'POST',
            headers: headers,
            body: formData
          }).then(resp => resp.json()).then((json) => {
                if(json.errors){
                    let validation = {}
                    if (json.errors.mobile_phone_number){                  
                        validation.mobile_phone_number = json.errors.mobile_phone_number
                        this.setState({ errors: validation })
                    }else if(json.errors.email){
                        validation.email = json.errors.email
                        this.setState({ errors: validation })
                    }else{
                        this.setState({errors:json.errors})
                        return;
                    }
                }else{
                    this.setState({complementInfoModal:false})
                }
          })
    }

    render() {
        if (!this.state.complementInfoModal){
            return null;
        }
        return (
            <ComplementModal show={this.state.complementInfoModal} onHide={() => this.setState({ complementInfoModal: false })} size="lg" contentClassName="bg-neutral-cloud-200 neutral-charcoal p-3"  backdrop="static" centered>
              <Modal.Body>
                  <h3 className='mb-2 n-fs-5 n-md-fs-4'>Komplett information</h3>
                  <p class="n-fs-6">Treebula strävar alltid att förbättra tjänsten. Vi ber dig därför att nu komplettera information som vi saknar.</p>
                  {/* Choose Roles for user if they dont have any */}
                  {!Window.user_data.userHasRoles ? (
                  <div>
                      <VBMultiOption
                      ref = {this.rolesRef}
                      options={Window.user_complement_data.lists.roles}    
                      label={<label>Ange din roll(er)</label>}
                      optionsContainerStyle={{zIndex:1056}}
                      onChange={(val) => {
                          this.setState({ roles: val.map((itm) => itm.value) });
                      }}
                      error={this.state.errors?.roles}
                      />
                  </div>
                  ) : null}
                  {/* Choose Muni for user if they dont have any */}
                  {!Window.user_data.userHasMuni ? (
                  <div>
                      <VBMultiOption
                      ref = {this.munisRef}
                      options={Window.user_complement_data.lists.munis}
                      label={<label className="mt-3">Välj kommun(er)</label>}
                      optionsContainerStyle={{zIndex:1056}}
                      onChange={(val) => {
                          this.setState({ munis: val.map((itm) => itm.value) });
                      }}
                      error={this.state.errors?.muni}
                      />
                  </div>
                  ) : null}
                  {/* Enter Telephone Number for user if they dont have any */}
                  {!Window.user_data.userHasPhone ? (
                  <div>
                      <VBPhoneInput
                      ref = {this.phoneInputRef}
                      label={
                          <label className="mt-3">Ange ditt telefonnummer: </label>
                      }
                      onChange={(val) => {
                          let errors = {}
                          errors.mobile_phone_number = null
                          this.setState({ phoneno: val, errors:errors });
                      }}
                      error={this.state.errors?.mobile_phone_number}
                      />
                  </div>
                  ) : null}

                  {!Window.user_data.userHasEmail ? (
                    <div>
                        <VBInput
                        ref={this.emailInput}
                        type="email"
                        placeholder="E-postadress"
                        label={<label className="mb-3 mt-3">E-postadress</label>}
                        error={this.state.errors?.email}
                        onFocus={() => this.setState({ errors: { ...this.state.errors } })}
                        onChange={(val) => {
                            let errors = {}
                            errors.email = null
                            this.setState({email : val, errors:errors }); }} />
                    </div>
                  ): null}
                  <div className='row justify-content-end'>
                      <button class="n-btn btn-forest-green mt-3 col-md-3 col-6" onClick={() => {this.submit();}}>Fortsätt</button>
                  </div>
              </Modal.Body>
            </ComplementModal>
          );
    }
}
export default ComplementInfo;