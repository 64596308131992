import Cookies from 'js-cookie';

function getShapeInfo(geojson){
    return fetch("/map-api-data/", {
        method: "POST",
        body: JSON.stringify(geojson),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          },
    }).then(resp => resp.json()).then(data => data)
}

function getSBPPdf(alder_chart, activeProps){
    let target = "/map-api/get-sbp-pdf/"
    return fetch(target, {
        method: "POST",
        body: JSON.stringify({alder_chart: alder_chart, active_props: activeProps}),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Window.csrfToken
        }
    }).then(resp => resp.json()).then((data) => {
        return data;
    });
}

function searchProps(text){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/props/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body:JSON.stringify({text: text})
    }).then(resp => {
        if(resp.ok) {
            return resp.json()
        } else {
            alert('Oops! Något gick fel. Ring 08-33 99 44 så hjälper vi dig vidare.')
            window.location.reload()
            return;
        }
    }).then(json => json)
}

function getProps(id){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/prop/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify({"lmID": id})
        })
    .then(resp => resp.json()).then((json) => {
        return json["geojson"]
    })
    
}

function getPropsAndArea(id){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/prop/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify({"lmID": id})
        })
    .then(resp => resp.json()).then((json) => {
        return json
    })
    
}

function getSBO(id, label, onFinish){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/get-sbp/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body:JSON.stringify({"FBAID": id})
        })
    .then(resp => resp.json()).then((json) => {
        onFinish({value: id, label: label, locked: false}, json);
    })
}

function updateSectionsProcentage(id, name, value){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/user-update-sections-procentage/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body:JSON.stringify({propID: id, field_name: name, field_value: value, aggregate: aggregate})
        })
    .then(resp => resp.json()).then(json => json)
}

function createEmptyProp(id, label){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/create-empty-prop/", {
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body:JSON.stringify({lmID: id, label: label})
    }).then(resp => {
        if(resp.ok){
            return resp.json();
        } else{
            alert('Oops! Något gick fel. Ring 08-33 99 44 så hjälpa vi dig vidare.')
        }
    })
}

async function fetchGeojsonData(geojson) {
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    };
  
    try {
      const resp = await fetch("/map-api/divide-and-data-only/", {
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify(geojson),
      });
  
      if (resp.ok) {
        return resp.json();
      } else {
        console.error('Server returned an error:', resp.statusText);
        throw new Error('Server returned an error');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
}

function fetchProp(id) {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/prop/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify({lmID: id})
        })
    .then(resp => resp.json()).then((json) => {
        return json
    })
}

// We have this function in order to export getProp and still use fetchProp in another function with in this file.
function getProp(id){
    return fetchProp(id)
}

function getForestandProp(id){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/get-sbp/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body:JSON.stringify({FBAID: id})
        })
    .then(resp => resp.json()).then((json) => {
        return json
    })
}

function fetchPropertyAndData(id, label, geojson) {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/divide-and-data/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body:JSON.stringify({geojson: geojson, propID: id, label: label})
        }).then(resp => {
            if(resp.ok) {
                return resp.json()
            } else {
                return {is_broken: "error"}
            }
        })
}

function fetchProperties(props) {
    return Promise.all(
        props.map(async (p) => {
            if(p.value.startsWith('FBA')) {
                const dividedData = await getForestandProp(p.value)
                return { info: p, BorderGeojson: null, json: dividedData }
            } else {
                const getProp = await fetchProp(p.value);
                const dividedData = await fetchPropertyAndData(p.value, p.label, getProp.geojson)
                return { info: p, BorderGeojson: getProp.geojson, json: dividedData }
            }
        })
    )
}

function unlockProperty(lmID) {
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch(`/map-api/unlock-property/`,{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify({lmID: lmID})
    })
}

function deleteProperty(propId){
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
    return fetch("/map-api/user-delete-property/",{
        method: 'POST',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify({propID: propId})
    }).then((resp)=>{
        if(resp.ok){
            //Update the 'activeProps' cookie
            let activePropsCookie = Cookies.get('activeProps')

            if(activePropsCookie){
                let propList = activePropsCookie.split(',').filter(prop => prop != propId)
                Cookies.set('activeProps', propList.map(p => p).join(','));
            }            
            return resp.json();
        } else {
            alert('Oops! Något gick fel. Ring 08-33 99 44 så hjälpa vi dig vidare.')
        }
    })
}


export {getShapeInfo, searchProps, getSBPPdf, fetchProperties, getProp, getProps, getPropsAndArea, getSBO, createEmptyProp, updateSectionsProcentage, deleteProperty, unlockProperty, fetchGeojsonData };